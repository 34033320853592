import { BrowserRouter, Route, Routes } from "react-router-dom";
import Top from "components/pages/top";
import Register from "components/login/registar";
import Login from "components/login/login";
import Mypage from "components/pages/mypage";
import UserList from "components/pages/users/userList";
import PostList from "components/pages/posts/postList";
import PostDetail from "components/pages/posts/project/projectDetail";
import PostCreate from "components/pages/posts/postCreate";
import PostEdit from "components/pages/posts/postEdit";
import UserEdit from "components/pages/users/userEdit";
import ExpertPostCreate from "components/pages/posts/expertPosts/expertPostCreate";
import RecruitPostCreate from "components/pages/posts/recruitPosts/recruitPostCreate";
import RecentPostCreate from "components/pages/posts/recentPosts/recentPostCreate";
import Message from "components/pages/message/message";
import Top2 from "components/pages/top2";
import CreateProject from "components/pages/posts/project/CreateProject";
import ProjectEdit from "components/pages/posts/project/projectEdit";
import { TopNav } from "components/navigation/topNav";
import LP from "components/LP";
import RecentPostDetail from "components/pages/posts/recentPosts/recentPostDetail";
import PrivateBBS from "components/pages/posts/BBS/privateBBS";
import PublicBBS from "components/pages/posts/BBS/publicBBS";
import BBSdetail from "components/pages/posts/BBS/bbsDetail";

const Routers = () => {
  return (
    <BrowserRouter>
      <TopNav />
      <Routes>
        <Route path={`/Top1`} element={<Top />} />
        {/* <Route path={`/`} element={<Top />} /> */}
        {/* <Route path={`/Top1`} element={<Top2 />} /> */}
        <Route path={`/`} element={<Top2 />} />
        <Route path={`/register/`} element={<Register />} />
        <Route path={`/login/`} element={<Login />} />
        <Route path={`/user/:uid`} element={<Mypage />} />
        <Route path={`/userlist`} element={<UserList />} />
        <Route path={`/mypage/edit`} element={<UserEdit />} />
        {/* Posts */}
        <Route path={`/posts`} element={<PostList />} />
        <Route path={`/posts/:pid`} element={<PostDetail />} />
        <Route path={`/posts/edit`} element={<PostEdit />} />
        <Route path={`/posts/editproject`} element={<ProjectEdit />} />
        <Route path={`/postcreate`} element={<PostCreate />} />
        <Route path={`/CreateProject`} element={<CreateProject />} />
        {/* ExpertPosts */}
        <Route path={`/expertpostcreate`} element={<ExpertPostCreate />} />
        {/* RecruitPost */}
        <Route path={`/recruitpostcreate`} element={<RecruitPostCreate />} />
        {/* RecentPost*/}
        <Route path={`/recentpostcreate`} element={<RecentPostCreate />} />
        <Route path={`/recentpost/:tid`} element={<RecentPostDetail />} />
        {/* 掲示板*/}
        <Route path={`/bbs`} element={<PublicBBS />} />
        <Route path={`/bbs/private`} element={<PrivateBBS />} />
        <Route path={`/bbs/:bid`} element={<BBSdetail />} />
        {/* Message */}
        <Route path={`/message`} element={<Message />} />
        <Route path={`/LP202210`} element={<LP />} />
      </Routes>
    </BrowserRouter>
  );
};
export default Routers;
