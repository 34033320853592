import React, { useContext, useEffect } from "react";
import { TwitterShareButton, TwitterIcon } from "next-share";

const ShareSNS = (props: any) => {
  // console.log("propsをひょうじ", props);
  const data = props.data;
  let shortContent = data.content;
  if (data.content != undefined) {
    if (data.content.length > 100) {
      shortContent =
        data.content.replace(/\r?\n/g, "").trim().substr(0, 100) + "...";
    }
  }
  // console.log("shortContent", shortContent);
  const content = data.title + "\n" + shortContent;
  // const content = data.title + "\n" + data.content;

  return (
    <TwitterShareButton url={props.url} title={content}>
      <TwitterIcon size={32} round />
    </TwitterShareButton>
  );
};

export default ShareSNS;
