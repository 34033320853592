import { useState, useEffect, ReactNode, useContext } from "react";
import { db } from "components/firebase-config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import PublishIcon from "@mui/icons-material/Publish";
import NumberFormat from "react-number-format";
import { wrap } from "module";

const PostCreate = (props: any) => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event: {
    [x: string]: any;
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    const { content, title, industry, employment, investment } =
      event.target.elements;
    const postsCollectionRef = collection(db, "posts");
    // Employment関係をオブジェクトにまとめる←まだできてない
    addresses.map((address, index) => {
      const employmentInfo = [
        {
          type: Employment,
          description: "description",
          days: "days",
          reward: "reward",
        },
      ];
    });
    await addDoc(postsCollectionRef, {
      content: content.value,
      title: title.value,
      email: authInfo.email,
      category: "post",
      industry: Industry,
      employment: Employment,
      investment: Investment,
      investmentMoney: InvestmentMoney,
      uid: authInfo.uid,
      createDate: serverTimestamp(),
      updateDate: serverTimestamp(),
    });
    navigate("/");
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const industries = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const employments = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  function getStyles(
    name: string,
    personName: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  // export default function MultipleSelectChip() {
  const theme = useTheme();
  //////////////////////////
  /////// industry ///////
  //////////////////////////

  const [industry, setIndustry] = useState<string[]>([]);
  let Industry = industry;
  const handleIndustryChange = (event: SelectChangeEvent<typeof industry>) => {
    const {
      target: { value },
    } = event;
    setIndustry(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    Industry = industry;
  };
  //////////////////////////
  /////// employment ///////
  //////////////////////////

  const [employment, setEmployment] = useState<string[]>([]);

  const employmentInfo = {
    index: {
      type: "types",
      description: "description",
      days: "days",
      reward: "reward",
    },
  };
  let Employment = employment;
  const handleEmploymentChange = (
    event: SelectChangeEvent<typeof employment>
  ) => {
    const {
      target: { value },
    } = event;
    setEmployment(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    Employment = employment;
  };
  // フォーム追加
  const [addresses, setAdresses] = useState(["address.0"]);
  function addAddress() {
    const newAddresses = [...addresses];
    console.log(
      "before",
      newAddresses,
      addresses,
      `address.${addresses.length}`
    );
    newAddresses.push(`address.${addresses.length}`);
    setAdresses(newAddresses);
    console.log(newAddresses, addresses, `address.${addresses.length}`);
  }
  function removeAddress(index: number) {
    if (addresses.length > 1) {
      console.log("target", index);
      const removeArr = [...addresses];
      removeArr.splice(index, 1);
      setAdresses(removeArr);
    }
  }

  //////////////////////////
  /////// investment ///////
  //////////////////////////
  const [investment, setInvestment] = useState("");
  let Investment = investment;
  const handleInvestmentChange = (event: SelectChangeEvent) => {
    setInvestment(event.target.value as string);
    Investment = investment;
  };
  const [investmentMoney, setInvestmentMoney] = useState("");
  let InvestmentMoney = investmentMoney;
  const handleInvestmentMoneyChange = (value: string) => {
    setInvestmentMoney(value);
    InvestmentMoney = investmentMoney;
  };
  //////////////////////////
  ///////// return /////////
  //////////////////////////
  return (
    <div>
      <div className='App-content'>
        <Typography variant='h4' component='h2' mt={10} mb={3}>
          プロジェクト投稿
        </Typography>
        <form onSubmit={handleSubmit}>
          {/* プロジェクト名 */}
          <div>
            <FormControl sx={{ m: 1, width: 400 }}>
              <label>プロジェクト名</label>
              <input name='title' type='title' />
            </FormControl>
          </div>
          {/* プロジェクト概要 */}
          <div>
            <FormControl sx={{ m: 1, width: 400 }}>
              <label>ブロジェクト概要</label>
              {/* <input name="content" type="content" placeholder="内容" /> */}
              <TextareaAutosize
                aria-label='minimum height'
                minRows={3}
                // placeholder="プロジェクト概要"
                style={{ width: 400 }}
                name='content'
              />
            </FormControl>
          </div>
          {/* 業界 */}
          <div>
            <FormControl sx={{ m: 1, width: 400 }}>
              <label>業界</label>
              {/* <input name="industry" type="industry" placeholder="業界" /> */}
              <Select
                labelId='demo-multiple-chip-label'
                id='demo-multiple-chip'
                multiple
                value={industry}
                onChange={handleIndustryChange}
                input={<OutlinedInput id='select-multiple-chip' label='業界' />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {industries.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, industry, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* 募集職種 */}
          <div>
            <FormControl sx={{ m: 1, width: 400 }}>
              <label>募集職種</label>
              <button type='button' onClick={addAddress}>
                +
              </button>
              {addresses.map((address, index) => {
                return (
                  <div key={address}>
                    <label>Address</label>
                    <input type='text' name={address} />
                    <button
                      type='button'
                      onClick={() => {
                        removeAddress(index);
                      }}
                    >
                      -
                    </button>
                    <FormControl sx={{ m: 1, width: 400 }}>
                      <Select
                        labelId='demo-multiple-chip-label'
                        id='demo-multiple-chip'
                        multiple
                        value={employment}
                        onChange={handleEmploymentChange}
                        input={
                          <OutlinedInput
                            id='select-multiple-chip'
                            label='募集職種'
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {employments.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, employment, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                );
              })}
            </FormControl>
          </div>
          {/* 投資関連 */}
          <div>
            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={{ m: 1, width: 400 }}>
                <label>投資受付</label>
                <Box sx={{ flexDirection: "row", flexWrap: "wrap" }}>
                  <FormControl sx={{ m: 1, width: 200 }}>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={investment}
                      label='Investment'
                      onChange={handleInvestmentChange}
                    >
                      <MenuItem value={"true"}>投資受付中</MenuItem>
                      <MenuItem value={"false"}>投資受付停止中</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 160 }}>
                    <NumberFormat
                      thousandSeparator={true}
                      thousandsGroupStyle='thousand'
                      suffix='万円'
                      allowNegative={true}
                      decimalScale={0}
                      onValueChange={(values, sourceInfo) => {
                        console.log(values.value);
                        console.log(values.formattedValue);
                        console.log(values.floatValue);
                        handleInvestmentMoneyChange(values.value);
                      }}
                      customInput={TextField}
                      // size="small"
                      label='金額'
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      // sx={{ width: "25ch" }}
                    />
                  </FormControl>
                </Box>
              </FormControl>
            </Box>
          </div>
          <div>
            <button type='submit'>登録</button>
            <button type='submit'>更新</button>
            {/* <Button
              variant="contained"
              startIcon={<PublishIcon />}
              sx={{ m: 1 }}
            >
              Upload
            </Button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostCreate;
