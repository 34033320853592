import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  FormControl,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
} from "firebase/firestore";
import React, { Key, useContext, useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { db } from "components/firebase-config";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import { SubmitHandler, useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";

const Board = (props: any) => {
  /////////////////////////////////////
  ///////////// State定義 /////////////
  /////////////////////////////////////
  type commentType = {
    cid: Key | null | undefined;
    timestamp: Timestamp;
    content: string;
    name: string;
    role: string;
    uid: string;
    // id: string;
  };
  const { register, handleSubmit, control, watch, reset } =
    useForm<commentType>({
      defaultValues: {
        content: "",
      },
    });
  const [comments, setComments] = useState<commentType[]>([]);
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext); //自分の情報
  let now = Timestamp.fromDate(new Date()).toDate();

  console.log("propsをひょうじboard", props);
  const data = props.data;

  /////////////////////////////////////
  ///////////// 細かい処理 /////////////
  /////////////////////////////////////

  /////////////////////////////////////
  ////////// project,tweet判断 /////////
  /////////////////////////////////////
  const setCommentRef = () => {
    let tempRef = collection(
      db,
      data.firstCollection,
      data.firstId,
      "comments"
    );
    if (data.secondId != undefined) {
      tempRef = collection(
        db,
        data.firstCollection,
        data.firstId,
        data.secondCollection,
        data.secondId,
        "comments"
      );
    }
    console.log("tempRef", tempRef);
    return tempRef;
  };

  /////////////////////////////////////
  ///////////// コメント取得 ////////////
  /////////////////////////////////////
  const readComment = async () => {
    const postCommentRef = setCommentRef();
    const q = query(
      postCommentRef,
      orderBy("timestamp", "asc")
      // limit(5)
    );
    const querySnapshot = await getDocs(q);
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                cid: posts_doc.id,
              },
            ];
            setComments(post_array);
          }
        );
      })
    );
  };
  useEffect(() => {
    readComment();
  }, []);
  console.log("comments", comments);

  /////////////////////////////////////
  ///////////// コメント送信 ////////////
  /////////////////////////////////////
  const onSubmit: SubmitHandler<commentType> = async (data) => {
    // バリデーションチェックOK！なときに行う処理を追加
    console.log(data);
    const postCommentRef = setCommentRef();
    await addDoc(postCommentRef, {
      content: data.content,
      timestamp: now,
      // name: authInfo.name,
      uid: authInfo.uid,
      // id: ""
    });
    reset();
  };
  /////////////////////////////////////
  ////////////// return ///////////////
  /////////////////////////////////////
  return (
    <Container maxWidth='sm'>
      <Typography
        variant='h5'
        sx={{
          color: "primary.light",
          mb: 2,
        }}
      >
        コメント
      </Typography>
      {/* コメント表示 */}
      <Box>
        {comments.map((comment) => (
          <div key={comment.cid}>
            <Divider />
            <Typography
              variant='caption'
              component='div'
              color='primary.light'
              sx={{ mt: 0.5 }}
            >
              <NavLink to={`/user/${comment.uid}`} style={{ color: "white" }}>
                {comment.name}
              </NavLink>
              <Chip
                label={comment.role}
                size='small'
                sx={{ mx: 0.5 }}
                color='secondary'
                variant='outlined'
              />
              {comment.timestamp?.toDate().toLocaleString("ja-JP", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color='primary.light'>
              {comment.content}
            </Typography>
          </div>
        ))}
      </Box>
      {authInfo.isLogin == true ? (
        <>
          {/* 送信フォーム */}
          <Box sx={{ my: 3 }}>
            <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
              <TextareaAutosize
                required
                aria-label='minimum height'
                minRows={2}
                placeholder='コメント'
                style={{ fontSize: "small" }}
                {...register("content")}
              />
            </FormControl>
          </Box>
          <Box sx={{ textAlign: "right" }}>
            <Button
              color='secondary'
              variant='contained'
              size='large'
              onClick={handleSubmit(onSubmit)}
            >
              コメントする
            </Button>
          </Box>
        </>
      ) : (
        <Typography sx={{ mb: 1.5 }} color='primary.light'>
          ログイン後にコメントができます
        </Typography>
      )}
    </Container>
  );
};

export default Board;
