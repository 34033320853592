import React, { useState, useContext, useEffect } from "react";
import Link from "@mui/material/Link";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import { doc, getDoc } from "firebase/firestore";
import {
  Avatar,
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import GoogleIcon from "@mui/icons-material/Google";

export interface ILoginPageProps {}

const Login: React.FunctionComponent<ILoginPageProps> = (props) => {
  /* ↓state変数を定義 */
  const [loginEmail, setLoginEmail] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");

  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);

  const navigate = useNavigate();

  /* ↓関数「handleSubmit」を定義 */
  const HandleSubmit = async (e: { preventDefault: () => void }) => {
    console.log("login.ts_handleSubmit呼ばれたよ");
    console.log(e);
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword).then(
        (response) => {
          // console.log("login", { loginEmail });
          // console.log("loginPassword", { loginPassword });
          // console.log("login-responce.user.uid", response.user.uid);
          // console.log("login-responce.user", response.user);
          // console.log("login-auth.name", auth.name);
          //setcontext的なことをする
          setAuthInfo({
            email: loginEmail,
            isLogin: true,
            uid: response.user.uid,
            name: auth.name,
            userImageUrl: "https://picsum.photos/200/300",
            visited: true,
            role: authInfo.role,
          });
        }
      );
      navigate("/");
    } catch (error) {
      alert("メールアドレスまたはパスワードが間違っています");
    }
  };
  const signInWithGoogle = async () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((response) => {
        setAuthInfo({
          email: response.user.email,
          isLogin: true,
          uid: response.user.uid,
          name: auth.name,
          userImageUrl: "https://picsum.photos/200/300",
          visited: true,
          role: authInfo.role,
        });
        // console.log("GmailInfo", response);
        // console.log("GmailInfo.user", response.user);
        // console.log("GmailInfo.user.uid", response.user.uid);
        // console.log("login-auth.name", auth.name);
        navigate("/");
        // console.log("GoogleLogin", "できてんねぇ");
      })
      .catch((error) => {
        console.log(error);
        setAuthInfo({
          email: "",
          isLogin: false,
          uid: "",
          name: "",
          userImageUrl: "https://picsum.photos/200/300",
          visited: false,
          role: "",
        });
      });
  };
  const theme = createTheme();

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className='form'>
          <Container component='main' maxWidth='xs' sx={{ height: "100vh" }}>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                Login ROMEO
              </Typography>
              <Box
                component='form'
                onSubmit={HandleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  autoFocus
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                {/* <FormControlLabel
                control={<Checkbox value='remember' color='primary' />}
                label='Remember me'
              /> */}
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='error'
                  startIcon={<GoogleIcon />}
                  sx={{ mb: 2 }}
                  onClick={() => signInWithGoogle()}
                  disabled={authInfo.isLogin}
                >
                  Googleログイン
                </Button>
                <Grid container>
                  <Grid item xs>
                    {/* <Link href='#' variant='body2'>
                    Forgot password?
                  </Link> */}
                  </Grid>
                  <Grid item>
                    <Link href='/register' variant='body2'>
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Login;
