import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  // Select,
  SelectChangeEvent,
  Stack,
  TextareaAutosize,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { db } from "components/firebase-config";
import React, { useContext, useState } from "react";
import {
  SubmitHandler,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import NumberFormat from "react-number-format";
import Select from "react-select";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  addDoc,
  collection,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";

// フォームの型
type FormInput = {
  content: string;
  title: string;
  email: string;
};

const RecentPostCreate = (props: any) => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  const { register, handleSubmit, control, watch } = useForm<FormInput>({
    defaultValues: {
      content: "",
      title: "",
      email: `${authInfo.email}""`,
    },
  });
  let now = Timestamp.fromDate(new Date()).toDate();
  const location = useLocation();
  const [tempEdit, setTempEdit] = useState<any>(location.state || "");

  console.log("recentPostに渡したオブジェクト", tempEdit);
  console.log("recentPostに渡したpid", tempEdit.pid);
  // フォーム送信時の処理
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    // バリデーションチェックOK！なときに行う処理を追加
    console.log(data);
    console.log(data.content);
    const postsCollectionRef = collection(db, "posts", tempEdit.pid, "tweet");
    await addDoc(postsCollectionRef, {
      content: data.content,
      title: data.title,
      email: authInfo.email,
      category: "recentPost",
      uid: authInfo.uid,
      createDate: now,
      updateDate: now,
    });
    navigate("/");
  };

  // 戻るボタン
  const handleBack = async () => {
    navigate(-1);
  };

  console.log();
  return (
    <div className='form'>
      <Container maxWidth='sm' sx={{ pt: 5, mt: 15 }}>
        <Typography variant='h5' mb={3} sx={{ fontWeight: "bold" }}>
          活動報告
        </Typography>
        <Stack spacing={3}>
          {
            ////////////////////////
            /////// タイトル ////////
            ////////////////////////
          }
          <TextField
            required
            label='タイトル'
            type='title'
            {...register("title")}
          />
          {
            ////////////////////////
            /////// 近況報告 ////////
            ////////////////////////
          }
          <Box>
            <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
              <TextareaAutosize
                required
                aria-label='minimum height'
                minRows={3}
                placeholder='近況報告'
                {...register("content")}
              />
            </FormControl>
          </Box>
          {
            ////////////////////////
            //////// Submit ////////
            ////////////////////////
          }
        </Stack>
        <Stack spacing={3} sx={{ my: 10 }}>
          <Button
            color='secondary'
            variant='contained'
            size='large'
            onClick={handleSubmit(onSubmit)}
          >
            投稿する
          </Button>
          <Button
            variant='outlined'
            color='inherit'
            onClick={() => handleBack()}
          >
            戻る
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default RecentPostCreate;
