import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect, ReactNode, useContext, Key } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "components/firebase-config";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ShareSNS from "components/pages/parts/share";

type threadType = {
  bid: Key | null | undefined;
  createDate: Timestamp;
  updateDate: Timestamp;
  title: string;
  content: string;
  category: string;
  role: string;
  uid: string;
};

const PrivateBBS: React.FC = () => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  // ログインしていなければ一般掲示板に飛ばす
  useEffect(() => {
    if (authInfo.isLogin == false) navigate("/bbs");
  }, []);
  return (
    <div
      className='App-content mt-4'
      style={{
        // backgroundImage: `image-url('image/orange.png'})`,
        background: `linear-gradient(#f35d17, #9198e5)`,
      }}
    >
      <Stack
        alignItems='center'
        sx={{
          pt: 5,
          width: "90%",
          maxWidth: "md",
          minHeight: "100vh",
          mx: "auto",
          backgroundColor: "white",
        }}
      >
        <Container>
          <Typography variant='h5' color='primary.main' sx={{ mt: 3 }}>
            {authInfo.role}の専用掲示板
          </Typography>
          {/* スレッドリスト表示 */}
          <Container>
            <ThreadList authInfo={authInfo} />
          </Container>
          {/* スレッド作成 */}
          <Container sx={{ my: 10 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                sx={{ backgroundColor: "#ffc9a2" }}
              >
                <Typography>スレッドを立てる</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <CreateThread authInfo={authInfo} />
              </AccordionDetails>
            </Accordion>
          </Container>
        </Container>
      </Stack>
    </div>
  );
};

export default PrivateBBS;

const threadCollectionRef = collection(db, "thread");
const roleOption = ["起業家", "有識者", "投資家"];
// スレッド作成コンポーネント
const CreateThread = (props: any) => {
  /////////////////////////////////////
  ///////////// スレッド作成 ////////////
  /////////////////////////////////////
  const now = Timestamp.fromDate(new Date()).toDate();
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const { register, handleSubmit, control, watch, reset } = useForm<threadType>(
    {
      defaultValues: {
        title: "",
        content: "",
        category: "総合",
      },
    }
  );

  const onSubmit: SubmitHandler<threadType> = async (data) => {
    // バリデーションチェックOK！なときに行う処理を追加
    await addDoc(threadCollectionRef, {
      createDate: now,
      updateDate: now,
      title: data.title,
      content: data.content,
      category: data.category,
      threadType: "private",
      // role:authInfo.role,
      role: props.authInfo.role,
      uid: authInfo.uid,
    });
    reset();
  };

  // ログインしてなくてBBSに来た場合の処理
  if (
    props.authInfo.role != "投資家" &&
    props.authInfo.role != "起業家" &&
    props.authInfo.role != "有識者"
  ) {
    return <Typography>ログイン後にスレッドの作成ができます</Typography>;
  }

  return (
    <>
      {/* 送信フォーム */}
      <Stack spacing={2} sx={{ my: 3 }}>
        <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
          <TextareaAutosize
            required
            aria-label='minimum height'
            minRows={1}
            placeholder='スレッド名'
            style={{ fontSize: "small" }}
            {...register("title")}
          />
        </FormControl>
        <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
          <TextareaAutosize
            required
            aria-label='minimum height'
            minRows={3}
            placeholder='コメント'
            style={{ fontSize: "small" }}
            {...register("content")}
          />
        </FormControl>
      </Stack>
      <Box sx={{ textAlign: "right" }}>
        <Button
          color='secondary'
          variant='contained'
          size='large'
          onClick={handleSubmit(onSubmit)}
        >
          作成
        </Button>
      </Box>
    </>
  );
};

// スレッドリスト表示コンポーネント
const ThreadList = (props: any) => {
  const [threads, setThreads] = useState<threadType[]>([]);
  /////////////////////////////////////
  ///////////// 専用掲示板取得 ////////////
  /////////////////////////////////////
  const readPrivateThread = async () => {
    console.log("readThreadのAuthInfo", props.authInfo.role);
    const q = query(
      threadCollectionRef,
      where("role", "==", props.authInfo.role),
      where("threadType", "==", "private"),
      orderBy("updateDate", "asc")
      // limit(5)
    );
    const querySnapshot = await getDocs(q);
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                bid: posts_doc.id,
              },
            ];
            setThreads(post_array);
          }
        );
      })
    );
  };
  useEffect(() => {
    readPrivateThread();
  }, []);

  return (
    <>
      {/* スレッド表示 */}
      <Box>
        {threads.map((thread) => (
          <>
            <NavLink to={`/bbs/${thread.bid}`}>
              <Card
                sx={{
                  minWidth: 275,
                  backgroundColor: "primary.light",
                  my: 2,
                }}
              >
                <CardContent>
                  <div key={thread.bid}>
                    <Typography
                      variant='body1'
                      component='div'
                      color='primary.main'
                    >
                      {thread.title}
                    </Typography>
                    <Typography
                      sx={{
                        mb: 1.5,
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                      }}
                      color='primary.main'
                      variant='subtitle2'
                    >
                      {thread.content}
                    </Typography>
                    <Box sx={{ my: -1 }}>
                      <ShareSNS
                        url={window.location.href + "/" + thread.bid}
                        data={thread}
                      />
                    </Box>
                  </div>
                </CardContent>
              </Card>
            </NavLink>
          </>
        ))}
      </Box>
    </>
  );
};
