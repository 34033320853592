import { useState, useEffect, ReactNode, useContext } from "react";
import { auth, db } from "components/firebase-config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";

const RecruitPostCreate = (props: any) => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();

  /////////////////////////////////////
  /////////// 細かい処理 ///////////////
  /////////////////////////////////////
  // 表示時に最上部を表示する
  window.scrollTo(0, 0);

  /////////////////////////////////////
  /////////// アップロード //////////////
  /////////////////////////////////////
  const handleSubmit = async (event: {
    [x: string]: any;
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    const { content, title } = event.target.elements;
    const postsCollectionRef = collection(db, "posts");
    await addDoc(postsCollectionRef, {
      content: content.value,
      title: title.value,
      email: authInfo.email,
      uid: authInfo.uid,
      createDate: serverTimestamp(),
      updateDate: serverTimestamp(),
      category: "recruitPost",
      name: authInfo.name,
    });
    navigate("/");
  };

  return (
    <div className='form'>
      <Typography variant='h1' component='h2' mt={20}>
        顧問募集投稿
      </Typography>
      <form onSubmit={handleSubmit}>
        <div>
          <label>内容</label>
          <input name='content' type='content' placeholder='内容' />
        </div>
        <div>
          <label>タイトル</label>
          <input name='title' type='title' placeholder='タイトル' />
        </div>
        <div>
          <button>登録</button>
          <button>更新</button>
        </div>
      </form>
    </div>
  );
};

export default RecruitPostCreate;
