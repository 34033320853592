import React, { useState, useEffect, useContext } from "react";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase-config";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import GoogleIcon from "@mui/icons-material/Google";

const Register: React.FC = () => {
  /* ↓state変数を定義 */
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);

  /* ↓関数「handleSubmit」を定義 */
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    try {
      await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      navigate("/login");
    } catch (error) {
      alert("正しく入力してください");
    }
  };
  const theme = createTheme();

  const signInWithGoogle = async () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((response) => {
        setAuthInfo({
          email: response.user.email,
          isLogin: true,
          uid: response.user.uid,
          name: auth.name,
          userImageUrl: "https://picsum.photos/200/300",
          visited: true,
          role: authInfo.role,
        });
        // console.log("GmailInfo", response);
        // console.log("GmailInfo.user", response.user);
        // console.log("GmailInfo.user.uid", response.user.uid);
        // console.log("login-auth.name", auth.name);
        navigate("/");
        // console.log("GoogleLogin", "できてんねぇ");
      })
      .catch((error) => {
        console.log(error);
        setAuthInfo({
          email: "",
          isLogin: false,
          uid: "",
          name: "",
          userImageUrl: "https://picsum.photos/200/300",
          visited: false,
          role: authInfo.role,
        });
      });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className='form'>
          <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                SignUp ROMEO
              </Typography>
              <Box
                component='form'
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  autoFocus
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  value={registerPassword}
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                >
                  SignUp
                </Button>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='error'
                  startIcon={<GoogleIcon />}
                  sx={{ mb: 2 }}
                  onClick={() => signInWithGoogle()}
                  disabled={authInfo.isLogin}
                >
                  Google SignUp
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Register;
