import { useState, useEffect, ReactNode, useContext } from "react";
import { db, storage } from "components/firebase-config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import PublishIcon from "@mui/icons-material/Publish";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  SubmitHandler,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import Select from "react-select";
import NumberFormat from "react-number-format";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ImageLogo from "components/assets/image/image.svg";
import "components/assets/css/ImageUpload.css";
import { Image } from "mui-image";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const ProjectEdit = (props: any) => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [tempEdit, setTempEdit] = useState<any>(location.state || "");
  const [employment, setEmployment] = useState<any>();

  console.log("postEditに渡したオブジェクト", tempEdit);
  console.log("postEditに渡したオブジェクト-", tempEdit.imageUrl);
  ////////////////////////////////////
  ////////// フォームの設定 ////////////
  ////////////////////////////////////
  // フォームの型
  type FormInput = {
    timestamp: Timestamp;
    imageUrl: any;
    title: string;
    content: string;
    slideShere: string;
    investment: string;
    investmentMoney: string;
    industry: string;
    employment: {
      job: string;
      detail: string;
      weekly: string;
      days: string;
      unit: string;
      reward: string;
    }[];
  };
  const { register, handleSubmit, control, watch } = useForm<FormInput>({
    defaultValues: {
      employment: tempEdit.employment,
      investmentMoney: "",
      imageUrl: tempEdit.imageUrl,
    },
  });
  //////////////////////
  // investmentMoney ///
  //////////////////////
  const [investmentMoney, setInvestmentMoney] = useState(
    tempEdit.investmentMoney
  );
  //////////////////////
  //// リクルート関連 ////
  //////////////////////
  // 動的フォーム用処理
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employment",
    keyName: "key",
  });
  const watchFieldArray = watch("employment");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  //////////////////////////
  ////// Select Props //////
  //////////////////////////
  const industries = [
    { value: "IT", label: "IT" },
    { value: "飲食", label: "飲食" },
    { value: "金融", label: "金融" },
    { value: "小売", label: "小売" },
    { value: "メディア", label: "メディア" },
    { value: "メーカー", label: "メーカー" },
  ];
  const employments = [
    { value: "エンジニア", label: "エンジニア" },
    { value: "デザイナー", label: "デザイナー" },
    { value: "PjM", label: "PjM" },
    { value: "PdM", label: "PdM" },
    { value: "企画、経営", label: "企画、経営" },
    { value: "経理", label: "経理" },
    { value: "法務", label: "法務" },
    { value: "マーケティング", label: "マーケティング" },
  ];
  const unitOpt = [
    { value: "日間", label: "日間" },
    { value: "週間", label: "週間" },
    { value: "ヶ月間", label: "ヶ月間" },
    { value: "年間", label: "年間" },
  ];
  const investmentOpt = [
    { value: "true", label: "投資受付中" },
    { value: "false", label: "投資受付停止中" },
  ];

  //////////////////////////////
  ////////// Update ////////////
  //////////////////////////////
  const editPostRef = doc(db, "posts", tempEdit.pid);

  // フォーム送信時の処理
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    // バリデーションチェックOK！なときに行う処理を追加
    let now = Timestamp.fromDate(new Date()).toDate();
    console.log(data);
    console.log(data.content);
    await updateDoc(editPostRef, {
      imageUrl: image,
      content: data.content,
      title: data.title,
      slideShere: data.slideShere,
      email: authInfo.email,
      category: "project",
      industry: data.industry,
      employment: data.employment,
      investment: data.investment,
      investmentMoney: investmentMoney,
      uid: authInfo.uid,
      updateDate: now,
    });
    navigate(-1);
  };

  ////////////////////////////
  ///////// 画像表示 //////////
  ////////////////////////////
  // const [image, setImage] = useState<string>(ImageLogo);
  const [image, setImage] = useState<string>(tempEdit.imageUrl);
  useEffect(() => {
    if (tempEdit.imageUrl == undefined) {
      setImage(ImageLogo);
      console.log("SetImageしたい");
    }
  }, []);
  ////////////////////////////
  ////// 画像アップロード ///////
  ////////////////////////////
  const OnFileUploadToFirebase = (e: any) => {
    // console.log(e.target.files[0].name);
    const file = e.target.files[0];
    const storageRef = ref(storage, "image/" + authInfo.uid + "/" + file.name);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a file!");
      getDownloadURL(storageRef)
        .then((url) => {
          setImage(url);
        })
        .catch((err) => console.log(err));
    });
  };

  // 戻るボタン
  const handleBack = async () => {
    navigate(-1);
  };

  return (
    <>
      <div className='form'>
        <Container maxWidth='sm' sx={{ mt: 15 }}>
          <Typography variant='h4' component='h2' sx={{ mb: 10 }}>
            プロジェクト編集
          </Typography>
          <Stack spacing={3}>
            {" "}
            {
              ////////////////////////
              ///// 画像アップロード ////
              ////////////////////////
            }
            <div className='outerBox'>
              <div className='title'>
                <h2>画像をアップロード</h2>
                <p>jpg,jpeg,pngファイルに対応しています</p>
              </div>
              <div className='imageUplodeBox'>
                <div className='imageLogoAndText'>
                  <Image
                    src={image}
                    alt='imagelogo'
                    fit='scale-down'
                    duration={0}
                  />
                  <p>{image == ImageLogo ? "ここにドラッグ＆ドロップ" : ""}</p>
                </div>
                <input
                  className='imageUploadInput'
                  multiple
                  // ref={register}
                  // name='imageURL'
                  type='file'
                  accept='.png, .jpeg, .jpg'
                  onChange={OnFileUploadToFirebase}
                  // {...register("imageURL")}
                />
              </div>
              <Button variant='contained' sx={{ mt: 3 }}>
                ファイルを選択
                <input
                  className='imageUploadInput'
                  type='file'
                  accept='.png, .jpeg, .jpg'
                  onChange={OnFileUploadToFirebase}
                  // {...register("imageURL")}
                />
              </Button>
            </div>
            {
              ////////////////////////
              ///// プロジェクト名 /////
              ////////////////////////
            }
            <TextField
              required
              label='プロジェクト名'
              type='title'
              defaultValue={tempEdit.title}
              {...register("title")}
            />
            {
              ////////////////////////
              //// プロジェクト概要 /////
              ////////////////////////
            }
            <Box>
              <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
                <InputLabel
                  style={{ backgroundColor: "#fff", padding: "0 7px" }}
                  shrink
                >
                  プロジェクト概要
                </InputLabel>
                <TextareaAutosize
                  required
                  aria-label='minimum height'
                  minRows={3}
                  defaultValue={tempEdit.content}
                  {...register("content")}
                />
              </FormControl>
            </Box>
            {
              ////////////////////////
              ///// SlideShere /////
              ////////////////////////
            }
            <Box>
              <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
                <InputLabel
                  style={{ backgroundColor: "#fff", padding: "0 7px" }}
                  shrink
                >
                  SlideShare - Embed
                </InputLabel>
                <TextareaAutosize
                  // required
                  aria-label='SlideShare - Embed'
                  minRows={3}
                  // label='SlideShare - Embed'
                  defaultValue={tempEdit.slideShere}
                  // type='slideShere'
                  {...register("slideShere")}
                />
              </FormControl>
              <Box style={{ fontSize: 14 }}>
                <Typography variant='caption' sx={{ mt: 0 }}>
                  ※SlideShareの埋め込みコード(Embed)を入力してください。
                </Typography>
                <a target='_blank' href='https://aprico-media.com/posts/3765'>
                  参考サイト
                </a>
              </Box>
            </Box>
            {
              ////////////////////////
              //////// 業界関連 ///////
              ////////////////////////
            }
            <Box sx={{ flexDirection: "row", flexWrap: "wrap" }}>
              <FormControl sx={{ width: 400 }}>
                <Controller
                  name='industry'
                  control={control}
                  defaultValue={tempEdit.industry}
                  render={({ field }) => (
                    <>
                      <InputLabel
                        style={{ backgroundColor: "#fff", padding: "0 7px" }}
                        shrink
                      >
                        業界
                      </InputLabel>
                      <Select
                        isMulti
                        options={industries}
                        value={industries.filter((x) =>
                          field.value?.includes(x.value)
                        )}
                        onChange={(newValue) => {
                          field.onChange(newValue.map((x) => x.value));
                        }}
                        placeholder=''
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </>
                  )}
                />
              </FormControl>
            </Box>
            {
              ////////////////////////
              /////// リクルート //////
              ////////////////////////
            }
            {controlledFields.map((field, index) => {
              return (
                <div key={field.key}>
                  <Box
                    sx={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      borderRadius: 2,
                      p: 2,
                      bgcolor: "#efefef",
                    }}
                  >
                    <FormControl sx={{ width: 400 }}>
                      <Controller
                        name={`employment.${index}.job`}
                        control={control}
                        defaultValue={tempEdit.employment[0].job}
                        render={({ field }) => (
                          <>
                            <InputLabel
                              style={{
                                backgroundColor: "#fff",
                                padding: "0 7px",
                              }}
                              shrink
                            >
                              募集職種
                            </InputLabel>
                            <Select
                              isMulti
                              // defaultValue={null}
                              options={employments}
                              value={employments.filter((x) =>
                                field.value?.includes(x.value)
                              )}
                              onChange={(newValue) => {
                                field.onChange(newValue.map((x) => x.value));
                              }}
                              placeholder=''
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                    <FormControl
                      sx={{ width: "100%", maxWidth: "sm", mt: 2 }}
                      defaultValue={tempEdit.employment[0].detail}
                    >
                      <InputLabel
                        style={{ backgroundColor: "#fff", padding: "0 7px" }}
                        shrink
                      >
                        募集詳細
                      </InputLabel>
                      <TextareaAutosize
                        // required
                        defaultValue={field.detail}
                        aria-label='minimum height'
                        minRows={2}
                        {...register(`employment.${index}.detail`)}
                      />
                    </FormControl>
                    <Grid container>
                      <FormControl sx={{ mt: 2, ml: 1, width: 150 }}>
                        <Controller
                          control={control}
                          name={`employment.${index}.weekly`}
                          defaultValue={tempEdit.weekly}
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              customInput={TextField}
                              label='予定労働時間(週)'
                              InputLabelProps={{ shrink: true }}
                              size='small'
                              thousandSeparator={true}
                              prefix='週'
                              suffix='時間'
                              name={name}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <FormControl sx={{ mt: 2, ml: 1, width: 150 }}>
                      <TextField
                        type='number'
                        label='期間'
                        InputLabelProps={{ shrink: true }}
                        {...register(`employment.${index}.days` as const, {
                          valueAsNumber: true,
                        })}
                        size='small'
                      />
                    </FormControl>
                    <FormControl sx={{ width: 120, mt: 2, ml: 1 }}>
                      <Controller
                        name={`employment.${index}.unit`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <InputLabel
                              style={{
                                backgroundColor: "#fff",
                                padding: "0 7px",
                              }}
                              shrink
                            >
                              期間単位
                            </InputLabel>
                            <Select
                              options={unitOpt}
                              value={unitOpt.find(
                                (x) => x.value === field.value
                              )}
                              onChange={(newValue) => {
                                field.onChange(newValue?.value);
                              }}
                              placeholder=''
                            />
                          </>
                        )}
                      />
                    </FormControl>
                    <FormControl sx={{ mt: 2, ml: 1, width: 150 }}>
                      <Controller
                        control={control}
                        name={`employment.${index}.reward`}
                        render={({ field: { onChange, name, value } }) => (
                          <NumberFormat
                            customInput={TextField}
                            label='報酬'
                            InputLabelProps={{ shrink: true }}
                            size='small'
                            thousandSeparator={true}
                            suffix='円'
                            name={name}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                    {/* クリックで行を削除するIconButtonを2行目から表示 */}
                    {index > 0 && (
                      <Stack>
                        <IconButton
                          color='inherit'
                          onClick={() => remove(index)}
                          // size="small"
                          sx={{ mt: 2 }}
                        >
                          <ClearOutlinedIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </Box>
                </div>
              );
            })}
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={() =>
                // クリックで行が増える
                // 増やした行の初期値を設定
                append({
                  job: "",
                  detail: "",
                  weekly: "",
                  days: "",
                  unit: "",
                  reward: "",
                })
              }
              // leftIcon={<AddIcon />}
            >
              行を追加
            </Button>
            {
              ////////////////////////
              //////// 投資関連 ///////
              ////////////////////////
            }
            <Box sx={{ flexDirection: "row", flexWrap: "wrap" }}>
              <FormControl sx={{ width: 200, ml: 1, mt: 3 }}>
                <Controller
                  name='investment'
                  control={control}
                  defaultValue={tempEdit.investment}
                  render={({ field }) => (
                    <>
                      <InputLabel
                        style={{ backgroundColor: "#fff", padding: "0 7px" }}
                        shrink
                      >
                        投資可否
                      </InputLabel>
                      <Select
                        options={investmentOpt}
                        value={investmentOpt.find(
                          (x) => x.value === field.value
                        )}
                        onChange={(newValue) => {
                          field.onChange(newValue?.value);
                        }}
                        placeholder=''
                      />
                    </>
                  )}
                />
              </FormControl>
              <FormControl sx={{ ml: 1, mt: 3, width: 160 }}>
                <Controller
                  control={control}
                  name='investmentMoney'
                  defaultValue={tempEdit.investmentMoney}
                  render={({ field: { onChange, name, value } }) => (
                    <NumberFormat
                      customInput={TextField}
                      label='金額'
                      InputLabelProps={{ shrink: true }}
                      size='small'
                      thousandSeparator={true}
                      suffix='万円'
                      name={name}
                      value={investmentMoney}
                      onChange={(e: any) =>
                        setInvestmentMoney(e.currentTarget.value)
                      }
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Stack>
          {
            ////////////////////////
            //////// Submit ////////
            ////////////////////////
          }
          <Stack spacing={3} sx={{ my: 10 }}>
            <Button
              color='secondary'
              variant='contained'
              size='large'
              onClick={handleSubmit(onSubmit)}
            >
              更新
            </Button>
            <Button
              variant='outlined'
              color='inherit'
              onClick={() => handleBack()}
            >
              戻る
            </Button>
          </Stack>
        </Container>
      </div>
    </>
  );
};

export default ProjectEdit;
