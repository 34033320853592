import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import { db } from "components/firebase-config";
import {
  doc,
  getDoc,
  collection,
  DocumentData,
  deleteDoc,
  query,
  getDocs,
  setDoc,
  where,
  orderBy,
  QuerySnapshot,
  limit,
  Timestamp,
} from "firebase/firestore";
import React, { Key, ReactNode, useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Stack, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Image } from "mui-image";
import { Ratio } from "react-bootstrap";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EditIcon from "@mui/icons-material/Edit";
import Board from "components/pages/parts/posts/board";
import ShareSNS from "components/pages/parts/share";

const RecentPostDetail = (props: any) => {
  /////////////////////////////////////////////
  /////////////////// 定義 /////////////////////
  /////////////////////////////////////////////
  const navigate = useNavigate();
  const params = useParams();
  const tid: string = params.tid || "";
  const location = useLocation();
  const [tempEdit, setTempEdit] = useState<any>(location.state || "");
  const [tweet, setTweet] = useState<DocumentData>([]);
  /////////////////////////////////////////////
  //////////////// 細かい処理 ///////////////////
  /////////////////////////////////////////////
  // Back
  const handleBack = async () => {
    navigate(-1);
  };
  // Delete
  const handleDelete = async () => {
    if (window.confirm("プロジェクトを完全に削除しますか？")) {
      // await deleteDoc(ref);
      await deleteDoc(doc(db, "posts", tid));
      navigate("/posts");
    } else {
      alert("cancel");
    }
  };
  /////////////////////////////////////////////
  /////////////// 近況報告取得 //////////////////
  /////////////////////////////////////////////
  const readTweet = async () => {
    const tweetDocumentRef = doc(db, "posts", tempEdit.pid, "tweet", tid);
    getDoc(tweetDocumentRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        const post = documentSnapshot.data();
        console.log("Document data:", documentSnapshot.data());
        const postuid: string = post.uid;
        setTweet(documentSnapshot.data());
        readPostUser(postuid);
      } else {
        console.log("No such document!");
      }
    });
  };
  /////////////////////////////////////
  ////////// PostUser取得 //////////////
  /////////////////////////////////////
  const [postUser, setPostUser] = useState<DocumentData>([]);
  const readPostUser = async (postuid: string) => {
    const PostUserDocumentRef = doc(db, "users", postuid);
    getDoc(PostUserDocumentRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        console.log("Detail user data:", documentSnapshot.data());
        setPostUser(documentSnapshot.data());
      }
    });
  };
  useEffect(() => {
    readTweet();
    // 表示時に最上部を表示する
    window.scrollTo(0, 0);
  }, []);
  // console.log("tweet", tweet);
  // console.log("postUser", postUser);
  /////////////////////////////////////////
  /////////// Componentに渡す情報 ///////////
  /////////////////////////////////////////
  const data = {
    title: tweet.title,
    content: tweet.content,
    url: tempEdit.url,
    type: "article",
  };
  console.log("props.url", tempEdit.url);

  const boardData = {
    firstId: tempEdit.pid, //postid
    secondId: params.tid, //tweetid
    firstCollection: "posts",
    secondCollection: "tweet",
  };

  return (
    <div className='App-content'>
      <Container sx={{ mt: 10, maxWidth: "sm" }}>
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "primary.main",
            my: 2,
          }}
        >
          <CardContent>
            <div key={tweet.pid}>
              <Typography
                variant='caption'
                component='div'
                color='primary.light'
              >
                {/* {recentPost.createDate} */}
                {tweet.createDate?.toDate().toLocaleString("ja-JP", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </Typography>
              <Typography variant='h5' component='div' color='primary.light'>
                {tweet.title}
              </Typography>
              <Typography color='primary.light'>{tweet.content}</Typography>
              <ShareSNS url={tempEdit.url} data={data} />
            </div>
          </CardContent>
        </Card>
        <Board data={boardData} />
        {/* <Board postId={tempEdit.pid} /> */}
      </Container>
    </div>
  );
};
export default RecentPostDetail;
