import emailjs from "@emailjs/browser";
import Message from "components/pages/message/message";
export default function sendMailFunc(props: any) {
  ///////////////////////////////////
  /////////// メール送信 //////////////
  ///////////////////////////////////
  console.log("SendMail実行された");
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  console.log("publicKey", publicKey);
  console.log("serviceID", serviceID);
  console.log("templateID", templateID);
  console.log("msgInfo", props);
  if (publicKey && serviceID && templateID) {
    emailjs.init("YOUR_PUBLIC_KEY");
    emailjs.send(serviceID, templateID, props, publicKey).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
    // エラーキャッチ必要かも？
  }
}

// 送信する情報
// to_name
// from_name
// Message
// to_email
