import React, { ReactNode, useContext, useEffect, useState } from "react";
import logo from "logo.svg";
import { auth, db, storage } from "components/firebase-config";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  orderBy,
  DocumentData,
  QuerySnapshot,
  limit,
} from "firebase/firestore";
import { NavLink, To, useNavigate } from "react-router-dom";
// import pcLogo from "components/assets/image/pc-Logo.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Modal,
  styled,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CompressOutlined,
  DisplaySettingsRounded,
  Filter7Sharp,
  FireExtinguisher,
} from "@mui/icons-material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { resolve } from "path";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import SortIcon from "@mui/icons-material/Sort";
import { textAlign } from "@mui/system";
import { serchProject } from "lib/algolia";
import { Ratio } from "react-bootstrap";
import { getDownloadURL, ref } from "firebase/storage";
import { Image } from "mui-image";
import topImg from "components/assets/image/ROMEO-logo1.jpg";

const Top2: React.FC = () => {
  ///////////////////////////////////////
  /////////////// 定義関係 ///////////////
  ///////////////////////////////////////
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const user: any = auth.currentUser;
  const navigate = useNavigate();
  type Project = {
    [x: string]: ReactNode;
    employment: [];
    pid: string;
  }[];
  const [projects, setProjects] = useState<any>([]);
  const [filters, setFilters] = useState<string>("category:project");

  //////////////////////////////////
  //////////// 細かい処理 ////////////
  //////////////////////////////////
  // 移動
  const moveWithInfo = async (path: To, category: string) => {
    navigate(path, {
      state: {
        category: category,
      },
    });
  };

  // タブの設定
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // 表示時に最上部を表示する
  window.scrollTo(0, 0);

  ///////////////////////////////////////
  /// Authで入った時usersのDBにあるか判定 ///
  ///         →なければ自動作成          ///
  ///////////////////////////////////////
  useEffect(() => {
    if (authInfo.uid != "") {
      const userDocumentRef = doc(db, "users", authInfo.uid);
      getDoc(userDocumentRef).then(async (documentSnapshot) => {
        if (documentSnapshot.exists()) {
          setAuthInfo({
            email: authInfo.email,
            isLogin: authInfo.isLogin,
            uid: authInfo.uid,
            name: documentSnapshot.get("name"),
            userImageUrl: documentSnapshot.get("userImageUrl"),
            visited: false,
            role: documentSnapshot.get("role"),
          });
          // console.log("authInfoをセット", authInfo);
        } else {
          console.log("No such document! I will make new user docment");
          // 存在しなければfirestoreにuidをキーとして作成
          await setDoc(doc(db, "users", authInfo.uid), {
            name: "プロフィールを編集してください",
            role: "",
            email: authInfo.email,
          });
        }
      });
    }
    setAuthInfo({
      email: authInfo.email,
      isLogin: authInfo.isLogin,
      uid: authInfo.uid,
      name: authInfo.name,
      userImageUrl: authInfo.userImageUrl,
      visited: authInfo.visited,
      role: authInfo.role,
    });
    readProject();
  }, []);
  ///////////////////////////////////////
  ///////// 初めての人をLPに飛ばす /////////
  ///////////////////////////////////////
  useEffect(() => {
    if (authInfo.isLogin == false) {
      if (authInfo.visited != true) {
        navigate("/LP202210");
      }
    }
  }, []);
  // console.log("authInfo", authInfo);
  ///////////////////////////////////////
  ///////////// フィルタ処理 //////////////
  ///////////////////////////////////////
  const exeFilter = async (value: string[], type: string) => {
    // console.log("exeFilterのValue", value);
    let filter = filters;
    let joinedValue = "";
    // console.log("exeFilterのjoinedValue", joinedValue);
    switch (type) {
      case "industry":
        joinedValue = value.join(" OR industry:");
        // filterの中に既に書いてあったらそれを削除する
        if (filter.includes(type)) {
          filter = filter.replaceAll(/ AND \(industry:.*?\)/g, "");
        }
        if (value.length > 0) {
          // filterに追加
          // setFilters(filter + ` AND (industry = ${joinedValue})`);
          filter = filter + ` AND (industry:${joinedValue})`;
        }
        break;
      case "job":
        joinedValue = value.join(" OR employment.job:");
        if (filter.includes(type)) {
          filter = filter.replaceAll(/ AND \(employment.job:.*?\)/g, "");
        }
        if (value.length > 0) {
          filter = filter + ` AND (employment.job:${joinedValue})`;
        }
        break;
      case "investment":
        joinedValue = value.join(" OR investment:");
        if (filter.includes(type)) {
          filter = filter.replaceAll(/ AND \(investment:.*?\)/g, "");
        }
        if (value.length > 0) {
          filter = filter + ` AND (investment:${joinedValue})`;
          filter = filter.replaceAll("投資受付中", "true");
          filter = filter.replaceAll("投資受付停止中", "false");
        }
        break;
    }
    // console.log("filterの最終形", filter);
    setFilters(filter);
    // let filterの例 = "category = project AND (industry=飲食 and IT) AND (job=デザイナー and エンジニア) AND (investment=投資受付中 and 投資受付停止中)";
    // filter = "category = project";
    // console.log("filterの最終形", filter);
    const result = await serchProject(filter);
    // const result = projects;
    // console.log("resulttttttt", result.hits);
    if (result.hits.length > 0) {
      if (result.hits.length > 0) {
        const project = result.hits.map((hit: any) => {
          return {
            ...hit,
            // employment:hit.content,
            // pid: hit.objectID,
          };
        });
        setProjects(project);
      } else {
        setProjects([]);
      }
    }
  };
  //////////
  // 業界 //
  //////////
  const industry = ["IT", "飲食", "金融", "小売", "メディア", "メーカー"];
  const [industryValues, setIndustryValues] = useState<string[]>([]);
  let industryVal: any[];
  const hndlIndChk = (e: any) => {
    if (industryValues.includes(e.target.value)) {
      setIndustryValues(
        industryValues.filter((checkedValue) => checkedValue !== e.target.value)
      );
      industryVal = industryValues.filter(
        (checkedValue) => checkedValue !== e.target.value
      );
    } else {
      setIndustryValues([...industryValues, e.target.value]);
      industryVal = [...industryValues, e.target.value];
    }
    exeFilter(industryVal, "industry");
  };
  // console.log("industryValues", industryValues);
  //////////
  // 募集 //
  //////////
  const job = [
    "エンジニア",
    "デザイナー",
    "PjM",
    "PdM",
    "企画、経営",
    "経理",
    "法務",
    "マーケティング",
  ];
  const [jobValues, setJobValues] = useState<string[]>([]);

  let jobVal: any[];
  const hndlJobChk = (e: any) => {
    if (jobValues.includes(e.target.value)) {
      setJobValues(
        jobValues.filter((checkedValue) => checkedValue !== e.target.value)
      );
      jobVal = jobValues.filter(
        (checkedValue) => checkedValue !== e.target.value
      );
    } else {
      setJobValues([...jobValues, e.target.value]);
      jobVal = [...jobValues, e.target.value];
    }
    exeFilter(jobVal, "job");
    // if (!jobValues) {
    //   setProjects([])
    // }else{

    // }
  };
  // console.log("jobValues", jobValues);
  //////////
  // 投資 //
  //////////
  const investment = ["投資受付中", "投資受付停止中"];
  const [investmentValues, setInvestmentValues] = useState<string[]>([]);

  // let investimentCheck: any[];
  let investimentVal: any[];
  const hndlInvestmentChk = (e: any) => {
    if (investmentValues.includes(e.target.value)) {
      setInvestmentValues(
        investmentValues.filter(
          (checkedValue) => checkedValue !== e.target.value
        )
      );
      investimentVal = investmentValues.filter(
        (checkedValue) => checkedValue !== e.target.value
      );
      // investimentCheckの投資可能、投資不可能とかをtrue,falseに変更する
    } else {
      setInvestmentValues([...investmentValues, e.target.value]);
      investimentVal = [...investmentValues, e.target.value];
      // investimentCheckの投資可能、投資不可能とかをtrue,falseに変更する
    }
    exeFilter(investimentVal, "investment");
  };
  // console.log("investimentValues", investmentValues);

  const FilterMenu = () => {
    return (
      <FormControl component='fieldset'>
        <FormLabel component='legend'>業界</FormLabel>
        <FormGroup aria-label='position' row>
          {industry.map((industry) => (
            <FormControlLabel
              value={industry}
              control={
                <Checkbox
                  onChange={hndlIndChk}
                  checked={industryValues.includes(industry)}
                />
              }
              label={industry}
            />
          ))}
        </FormGroup>
        <FormLabel component='legend'>募集職種</FormLabel>
        <FormGroup aria-label='position' row>
          {job.map((job) => (
            <FormControlLabel
              value={job}
              control={
                <Checkbox
                  onChange={hndlJobChk}
                  checked={jobValues.includes(job)}
                />
              }
              label={job}
            />
          ))}
        </FormGroup>
        <FormLabel component='legend'>投資可否</FormLabel>
        <FormGroup aria-label='position' row>
          {investment.map((investment) => (
            <FormControlLabel
              value={investment}
              control={
                <Checkbox
                  onChange={hndlInvestmentChk}
                  checked={investmentValues.includes(investment)}
                />
              }
              label={investment}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  };
  ///////////////////////////////////////
  ////////// プロジェクト関連処理 //////////
  ///////////////////////////////////////
  const readProject = async () => {
    const PostsCollectionRef = collection(db, "posts");
    const q = query(
      PostsCollectionRef,
      where("category", "==", "project"),
      orderBy("updateDate", "desc")
    );
    const querySnapshot = await getDocs(q);
    // post_arrayに入れてからsetStateする
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                pid: posts_doc.id,
              },
            ];
            setProjects(post_array);
          }
        );
      })
    );
  };
  {
    //////////////////////
    /////// 画像表示 //////
    //////////////////////
  }
  // const [image, setImage] = useState<string>("");
  // const firestorage = storage;
  // const gsReference = ref(
  //   firestorage,
  //   "gs://romeo-188d6.appspot.com/ROMEO-logo1.jpg"
  // );
  // getDownloadURL(gsReference)
  //   .then((url) => {
  //     setImage(url);
  //     console.log(url);
  //   })
  //   .catch((err) => console.log(err));

  //////////////////////////////////
  ////////////// modal /////////////
  //////////////////////////////////
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    p: 4,
  };

  const closeButtonStyle = {
    height: 0,
    textAlign: "right",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // if (authInfo.isLogin == true) {
  // console.log(projects);
  return (
    <div className='App-content mt-2 pt-5'>
      {
        ///////////////////////////
        ////// アコーディオン ///////
        ///////////////////////////
      }
      <Accordion>
        <AccordionSummary
          expandIcon={<SortIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          sx={{ backgroundColor: "#efefef" }}
        >
          <Typography>フィルター</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>業界</FormLabel>
            <FormGroup aria-label='position' row>
              {industry.map((industry) => (
                <FormControlLabel
                  value={industry}
                  control={
                    <Checkbox
                      onChange={hndlIndChk}
                      checked={industryValues.includes(industry)}
                    />
                  }
                  label={industry}
                />
              ))}
            </FormGroup>
            <FormLabel component='legend'>募集職種</FormLabel>
            <FormGroup aria-label='position' row>
              {job.map((job) => (
                <FormControlLabel
                  value={job}
                  control={
                    <Checkbox
                      onChange={hndlJobChk}
                      checked={jobValues.includes(job)}
                    />
                  }
                  label={job}
                />
              ))}
            </FormGroup>
            <FormLabel component='legend'>投資可否</FormLabel>
            <FormGroup aria-label='position' row>
              {investment.map((investment) => (
                <FormControlLabel
                  value={investment}
                  control={
                    <Checkbox
                      onChange={hndlInvestmentChk}
                      checked={investmentValues.includes(investment)}
                    />
                  }
                  label={investment}
                />
              ))}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      {
        ///////////////////////////
        //////// プロジェクト ///////
        ///////////////////////////
      }
      <Container>
        <Box sx={{ maxWidth: "md", mx: "auto" }}>
          <Image src={topImg} alt='' duration={0} />
        </Box>
        <Box
          sx={{
            width: "100%",
            my: 5,
            textAlign: "center",
            display: { xs: "block", md: "inline-flex" },
            justifyContent: "center",
          }}
        >
          <Button
            variant='contained'
            sx={{
              mx: { xs: 0, md: 2 },
              my: 1,
              width: { xs: "80%", md: "60%" },
              maxWidth: "700px",
              height: 50,
              borderRadius: "25px",
              backgroundColor: "secondary.light",
              color: "primary.light",
              fontWeight: "bold",
              fontSize: 20,
            }}
            onClick={() => navigate("/LP202210")}
          >
            ROMEOとは
          </Button>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <NavLink to={`/login`}>
            <Typography
              variant='body2'
              color='primary.light'
              sx={{
                mt: -3,
                mb: 5,
              }}
            >
              ログイン / 新規登録
            </Typography>
          </NavLink>
        </Box>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          sx={{ maxWidth: "xl" }}
        >
          {projects.map((project: any) => (
            <Grid item xs={12} md={6} lg={4}>
              <Card
                sx={{
                  minWidth: 275,
                  backgroundColor: "primary.main",
                  my: 2,
                  mx: 2,
                  // height: 460,
                }}
              >
                <div key={project.pid}>
                  <NavLink to={`/posts/${project.pid}`}>
                    <Ratio aspectRatio='16x9'>
                      <CardMedia
                        component='img'
                        height='140'
                        image={project.imageUrl}
                        alt={project.title + "のサムネイル"}
                        sx={{ objectFit: "contain" }}
                      />
                    </Ratio>
                    <CardContent>
                      <Typography
                        variant='h5'
                        component='div'
                        color='primary.light'
                        sx={{
                          mb: 1.5,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        }}
                      >
                        {project.title}
                      </Typography>
                      <Typography
                        sx={{
                          mb: 1.5,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                        }}
                        color='primary.light'
                      >
                        {project.content}
                      </Typography>
                      <Grid container>
                        {/* <Grid item xs /> */}
                        <Grid item xs={8}>
                          <Box sx={{ height: 30 }}>
                            {project.industry?.map((industry: any) => (
                              <Chip
                                label={industry}
                                size='small'
                                variant='outlined'
                                sx={{
                                  // mt: { xs: 0, md: -9 },
                                  mr: 0.5,
                                  color: "primary.light",
                                }}
                              />
                            ))}
                          </Box>
                          <Box>
                            {project.employment?.map((emp: any) => (
                              <>
                                {emp.job.map((job: any) => (
                                  <Chip
                                    label={job}
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                      // mt: { xs: 0, md: -9 },
                                      mr: 0.5,
                                      color: "#5fbbe0",
                                      borderColor: "#5fbbe0",
                                    }}
                                  />
                                ))}
                              </>
                            ))}
                          </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: "right" }}>
                          <Typography variant='caption' color='primary.light'>
                            {project.investment
                              .replace("true", "投資受付中")
                              .replace("false", "投資受付停止中")}
                          </Typography>
                          <Typography variant='body1' color='primary.light'>
                            {project.investmentMoney}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </NavLink>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Top2;
