import { useState, useEffect, ReactNode } from "react";
import { db } from "components/firebase-config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import {
  autocompleteClasses,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState<
    {
      [x: string]: ReactNode;
      uid: string;
    }[]
  >([]);
  const [documents, setDocuments] = useState<{ uid: string }[]>([]);
  // Read
  useEffect(() => {
    const usersCollectionRef = collection(db, "users");
    getDocs(usersCollectionRef).then((querySnapshot) => {
      querySnapshot.docs.forEach((doc) =>
        console.log("userlist-doc.data", doc.data())
      );
      setUsers(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }))
      );
    });
  }, []);

  return (
    <div>
      <div className='App-content'>
        <Typography variant='h1' component='h2' mt={20}>
          ユーザ一覧
        </Typography>
        {users.map((user) => (
          <Card
            sx={{
              width: 11 / 12,
              minWidth: 275,
              backgroundColor: "primary.main",
              my: 2,
              mx: "auto",
            }}
          >
            <CardContent>
              <div key={user.uid}>
                <NavLink to={`/user/${user.uid}`}>
                  <Typography
                    variant='h5'
                    component='div'
                    color='primary.light'
                  >
                    {user.name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color='primary.light'>
                    {user.email}
                  </Typography>
                  <Typography variant='body2' color='primary.light'>
                    {user.uid}
                  </Typography>
                </NavLink>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default UserList;
