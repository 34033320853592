import { useState, useEffect, ReactNode, useContext } from "react";
import { db } from "components/firebase-config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { Button, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import PublishIcon from "@mui/icons-material/Publish";
import CancelIcon from "@mui/icons-material/Cancel";

const PostEdit = (props: any) => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event: {
    [x: string]: any;
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    const { content, title } = event.target.elements;
    const postsCollectionRef = collection(db, "posts");
    await addDoc(postsCollectionRef, {
      content: content.value,
      title: title.value,
      email: authInfo.email,
      updateDate: serverTimestamp(),
    });
    navigate("/");
  };

  // Update
  const [tempEdit, setTempEdit] = useState<any>(location.state || "");
  const [title, setTitle] = useState(tempEdit.title);
  const [body, setBody] = useState(tempEdit.content);

  // console.log("postEditに渡したオブジェクト", tempEdit);
  // console.log("postEditに渡したオブジェクト-title", tempEdit.title);
  const editPostRef = doc(db, "posts", tempEdit.pid);

  const handleUpdate = async (event: {
    [x: string]: any;
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    console.log("handleUpdata", "はいった");
    // const { content, title } = event.target.elements;
    await updateDoc(editPostRef, {
      content: body,
      title: title,
      updateDate: serverTimestamp(),
    });
    navigate(-1);
  };

  const handleCancel = async () => {
    navigate(-1);
  };

  return (
    <div>
      <div className='App-content'>
        <Typography variant='h1' component='h2' mt={20}>
          編集画面
        </Typography>
        <form onSubmit={handleUpdate}>
          <div>
            <label>内容</label>
            <input
              name='content'
              type='content'
              placeholder='名前'
              defaultValue={tempEdit.content}
              onChange={(e) => setBody(e.target.value)}
            />
          </div>
          <div>
            <label>タイトル</label>
            <input
              name='title'
              type='title'
              placeholder='タイトル'
              defaultValue={tempEdit.title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <Stack direction='row' spacing={2}>
              <Button
                type='submit'
                variant='outlined'
                color='success'
                startIcon={<PublishIcon style={{ color: "green" }} />}
              >
                更新
              </Button>
              <Button
                variant='contained'
                color='error'
                startIcon={<CancelIcon style={{ color: "white" }} />}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostEdit;
