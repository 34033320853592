import React, { useState, useEffect } from "react";
import "index.css";

// 認証情報と認証情報セットのContext
export const AuthInfoContext = React.createContext<
  [AuthInfo, React.Dispatch<React.SetStateAction<AuthInfo>>]
>([
  {
    email: "",
    isLogin: false,
    uid: "",
    name: "",
    userImageUrl: "https://picsum.photos/200/300",
    visited: false,
    role: "",
  },
  () => {},
]);

type AuthInfo = {
  email: string | null;
  isLogin: boolean;
  uid: string;
  name: string;
  userImageUrl: string;
  visited: boolean;
  role: string;
};

type AuthComponentProps = {
  children: React.ReactNode;
};

/**
 * コンテキストのProvider
 */
export const AuthContextProvider: React.FC<AuthComponentProps> = React.memo(
  (props) => {
    // stateの定義
    const [authInfo, setAuthInfo] = useState<AuthInfo>(getDefaultAuthInfo());

    useEffect(() => {
      // authInfoに正しく値がセットされているかどうかをチェック,ブラウザストレージに情報を保存
      // console.log(authInfo);
      // console.log(authInfo.email);
      // console.log(authInfo.uid);
      // console.log(authInfo.name);
      if (authInfo.email) {
        setAutoInfoToLocalStorage(authInfo);
      } else {
        setAutoInfoToLocalStorage(authInfo);
      }
    }, [authInfo]);
    return (
      <AuthInfoContext.Provider value={[authInfo, setAuthInfo]}>
        {props.children}
      </AuthInfoContext.Provider>
    );
  }
);

/**
 * デフォルトのAuthInfoを取得
 * ローカルストレージから取得できた場合はその値をパース
 * 取得できない場合は空の情報を返す
 * @returns
 */
function getDefaultAuthInfo(): AuthInfo {
  const defaultAuthInfo = window.localStorage.getItem("authInfo");
  if (defaultAuthInfo) {
    return JSON.parse(defaultAuthInfo) as AuthInfo;
  } else {
    return {
      email: "",
      isLogin: false,
      uid: "",
      name: "",
      userImageUrl: "https://picsum.photos/200/300",
      visited: false,
      role: "",
    };
  }
}

/**
 * 認証情報をローカルストレージに追加
 * @param authInfo
 */
function setAutoInfoToLocalStorage(authInfo: AuthInfo): void {
  const authInfoStringfy = JSON.stringify(authInfo);
  window.localStorage.setItem("authInfo", authInfoStringfy);
}
