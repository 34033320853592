import { useState, useEffect, ReactNode, useContext } from "react";
import { db, storage } from "components/firebase-config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import PublishIcon from "@mui/icons-material/Publish";
import CancelIcon from "@mui/icons-material/Cancel";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import ImageLogo from "components/assets/image/image.svg";
import "components/assets/css/ImageUpload.css";
import { Image } from "mui-image";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const UserEdit = (props: any) => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  const location = useLocation();

  /////////////////////////////////////
  /////////// 細かい処理 ///////////////
  /////////////////////////////////////
  // 表示時に最上部を表示する
  window.scrollTo(0, 0);

  // Update
  const [tempEdit, setTempEdit] = useState<any>(location.state || "");
  // console.log("userEditに渡したオブジェクト", tempEdit);
  // console.log("userEditに渡したオブジェクト-name", tempEdit.name);
  ////////////////////////////////////
  ////////// フォームの設定 ////////////
  ////////////////////////////////////
  // フォームの型
  type FormInput = {
    userImageUrl: any;
    name: string;
    role: string;
    comment: string;
  };
  const { register, handleSubmit, control, watch } = useForm<FormInput>({
    defaultValues: {
      name: tempEdit.name,
      role: tempEdit.role,
      comment: tempEdit.comment,
      userImageUrl: tempEdit.userImageUrl,
    },
  });
  //////////////////////////
  ////// Select Props //////
  //////////////////////////
  const roleOption = [
    { value: "起業家", label: "起業家" },
    { value: "有識者", label: "有識者" },
    { value: "投資家", label: "投資家" },
  ];

  ////////////////////////////
  ///////// 画像表示 //////////
  ////////////////////////////
  // const [image, setImage] = useState<string>(ImageLogo);
  const [image, setImage] = useState<string>(tempEdit.userImageUrl);
  useEffect(() => {
    if (tempEdit.userImageUrl == undefined) {
      setImage(ImageLogo);
      console.log("SetImageしたい");
    }
  }, []);
  ////////////////////////////
  ////// 画像アップロード ///////
  ////////////////////////////
  const OnFileUploadToFirebase = (e: any) => {
    // console.log(e.target.files[0].name);
    const file = e.target.files[0];
    const storageRef = ref(storage, "image/" + authInfo.uid + "/" + file.name);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a file!");
      getDownloadURL(storageRef)
        .then((url) => {
          setImage(url);
        })
        .catch((err) => console.log(err));
    });
  };

  ////////////////////////////////////
  ////////// Update処理 ////////////
  ////////////////////////////////////
  const editUserRef = doc(db, "users", tempEdit.uid);

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    console.log("onSubmit", "はいった");
    console.log(data);
    await updateDoc(editUserRef, {
      userImageUrl: image,
      name: data.name,
      role: data.role,
      comment: data.comment,
    });
    setAuthInfo({
      email: authInfo.email,
      isLogin: authInfo.isLogin,
      uid: authInfo.uid,
      name: data.name,
      userImageUrl: image,
      visited: authInfo.visited,
      role: data.role,
    });
    navigate(-1);
  };

  const handleBack = async () => {
    navigate(-1);
  };

  return (
    <>
      <div className='form'>
        <Container maxWidth='sm' sx={{ mt: 15 }}>
          <Typography variant='h4' component='h2' sx={{ mb: 10 }}>
            ユーザ情報編集
          </Typography>
          <Stack spacing={3}>
            {" "}
            {
              ////////////////////////
              ///// 画像アップロード ////
              ////////////////////////
            }
            <div className='outerBox'>
              <div className='title'>
                <h2>画像をアップロード</h2>
                <p>jpg,jpeg,pngファイルに対応しています</p>
              </div>
              <div className='imageUplodeBox'>
                <div className='imageLogoAndText'>
                  <Image
                    src={image}
                    alt='imagelogo'
                    fit='scale-down'
                    duration={0}
                  />
                  <p>{image == ImageLogo ? "ここにドラッグ＆ドロップ" : ""}</p>
                </div>
                <input
                  className='imageUploadInput'
                  multiple
                  // ref={register}
                  // name='imageURL'
                  type='file'
                  accept='.png, .jpeg, .jpg'
                  onChange={OnFileUploadToFirebase}
                  // {...register("imageURL")}
                />
              </div>
              <Button variant='contained' sx={{ mt: 3 }}>
                ファイルを選択
                <input
                  className='imageUploadInput'
                  type='file'
                  accept='.png, .jpeg, .jpg'
                  onChange={OnFileUploadToFirebase}
                  // {...register("imageURL")}
                />
              </Button>
            </div>
            {
              ////////////////////////
              ///////// name /////////
              ////////////////////////
            }
            <TextField
              required
              label='名前'
              type='name'
              defaultValue={tempEdit.name}
              {...register("name")}
            />
            {
              ////////////////////////
              ///////// role /////////
              ////////////////////////
            }
            <Box sx={{ flexDirection: "row", flexWrap: "wrap" }}>
              <FormControl sx={{ width: 400 }}>
                <Controller
                  name='role'
                  control={control}
                  defaultValue={tempEdit.role}
                  render={({ field }) => (
                    <>
                      <InputLabel
                        style={{ backgroundColor: "#fff", padding: "0 7px" }}
                        shrink
                      >
                        Role
                      </InputLabel>
                      <Select
                        // isMulti
                        options={roleOption}
                        value={roleOption.filter((x) =>
                          field.value?.includes(x.value)
                        )}
                        onChange={(newValue) => {
                          field.onChange(newValue?.value);
                        }}
                        placeholder=''
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </>
                  )}
                />
              </FormControl>
            </Box>
            {
              ////////////////////////
              //// プロジェクト概要 /////
              ////////////////////////
            }
            <Box>
              <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
                <InputLabel
                  style={{ backgroundColor: "#fff", padding: "0 7px" }}
                  shrink
                >
                  自己紹介
                </InputLabel>
                <TextareaAutosize
                  required
                  aria-label='minimum height'
                  minRows={3}
                  defaultValue={tempEdit.comment}
                  {...register("comment")}
                />
              </FormControl>
            </Box>
          </Stack>

          <Stack spacing={3} sx={{ my: 10 }}>
            <Button
              color='secondary'
              variant='contained'
              size='large'
              onClick={handleSubmit(onSubmit)}
            >
              更新
            </Button>
            <Button
              variant='outlined'
              color='inherit'
              onClick={() => handleBack()}
            >
              戻る
            </Button>
          </Stack>
        </Container>
      </div>
    </>
  );
};

export default UserEdit;
