import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import { db } from "components/firebase-config";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  endAt,
  endBefore,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  startAt,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { SetStateAction, useEffect } from "react";
import { ReactNode, useCallback, useContext, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import InboxIcon from "@mui/icons-material/Inbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { userInfo } from "os";
// import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScroll from "react-infinite-scroller";
import { ContactMailOutlined } from "@mui/icons-material";
import sendMailFunc from "components/functions/sendMail";

const drawerWidth = 240;

// interface Props {
//   window?: () => Window;
// }

export default function Message(prop: any) {
  const location = useLocation();
  const [messageInfo, setMessageInfo] = useState<any>(location.state || ""); //宛先の情報
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext); //自分の情報
  // const now = Date.now();
  const [memberInfo, setMemberInfo] = useState<any>([]);
  const navigate = useNavigate();
  const params = useParams();
  // console.log("宛先の情報", messageInfo);
  // console.log("自分の情報", authInfo);

  // console.log("uid1_original", messageInfo.uid);
  // console.log("uid2_original", authInfo.uid);
  /////////////////////////////////////
  ////////////// 表示処理 //////////////  ←のちに上に移動する
  /////////////////////////////////////
  // console.log("message", messages);

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // console.log("messageInfo.uid", messageInfo.uid);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //////////////////////////////////////////
  ///////////////// mid作成 /////////////////
  //////////////////////////////////////////
  let mid = "";
  // UIDを数字に変換→足して２で割って文字列に戻す
  // stringを数字配列に変換
  const strigToNumber: any = (str: string) => {
    let array: any[] = [];
    for (var i = 0; i < str.length; ++i) {
      array = [...array, [str.codePointAt(i)]];
    }
    return array;
  };

  // ２つのarrayを計算する
  const calcArray = (array1: any, array2: any) => {
    let array: any[] = [];
    let arrayLength: number = 0;
    if (array1.length >= array2.length) {
      arrayLength = array1.length;
    } else {
      arrayLength = array2.length;
    }
    for (var i = 0; i < arrayLength; i++) {
      // 配列ないの文字列を数字にする
      let array1_num = Number(array1[i]);
      let array2_num = Number(array2[i]);
      let array_num = (array1_num + array2_num) / 2;
      let array_str = String.fromCharCode(array_num);
      array = [...array, array_str];
    }
    var array_mid = array.toString().replace(/,/g, "");
    return array_mid;
  };

  const uid2_num = strigToNumber(authInfo.uid);
  const getMid = () => {
    const uid1_num = strigToNumber(messageInfo.uid);

    mid = calcArray(uid1_num, uid2_num);
    // console.log("mid", mid);
  };
  if (messageInfo.uid != undefined) {
    getMid();
  }

  /////////////////////////////////////
  ///////////// State定義 /////////////
  /////////////////////////////////////
  interface msgType {
    timestamp: Timestamp;
    to: string;
    from: string;
    read: boolean;
    message: string;
    mid: string;
    mmid: string;
  }
  const [messages, setMessages] = useState<msgType[]>([]);
  const [oldMessages, setOldMessages] = useState<msgType[]>([]);
  const [newMessages, setNewMessages] = useState<msgType[]>([]);

  /////////////////////////////////////
  //////// 取得メッセージ数のリミット /////
  /////////////////////////////////////
  const [msgNum, setMsgNum] = useState(10);

  /////////////////////////////////////
  ////////// Read=trueにする処理 ///////
  //////// 引数としてメッセージを渡す /////
  /////////////////////////////////////
  const msgReadProp = async (message: any) => {
    const messageRef = doc(db, "message", message.mmid);
    // await setDoc(messageRef, message);
    // console.log("messageRef", messageRef);
    updateDoc(messageRef, {
      read: true,
    });
  };
  messages.map((doc) => {
    if (doc.read == false) {
      // console.log("messageのread", doc);
      msgReadProp(doc);
    }
  });
  newMessages.map((doc: any) => {
    if (doc.read == false) {
      // console.log("リアルタイムのread", doc);
      msgReadProp(doc);
    }
  });

  /////////////////////////////////////
  ///////// 初回メッセージ読み込み ////////
  /////////////////////////////////////
  const UseMessageListener = async (prop: string, limit_num: number) => {
    // messageコレクションを読み込む
    const MsgCollectionRef = collection(db, "message");
    const q = query(
      MsgCollectionRef,
      where("mid", "==", prop),
      orderBy("timestamp", "desc")
      // startAfter(now),
      // limit(limit_num)
    );
    const querySnapshot = await getDocs(q);
    let message: any = [];
    setMessages(
      querySnapshot.docs.map((doc) => ({
        // ...doc.data(),
        to: doc.data().to,
        from: doc.data().from,
        read: doc.data().read,
        message: doc.data().message,
        mid: doc.data().mid,
        timestamp: doc.data().timestamp,
        mmid: doc.id,
      }))
    );
  };
  useEffect(() => {
    UseMessageListener(mid, msgNum);
    ReadUser(messageInfo.uid);
  }, []);

  ///////////////////////////////////
  /////// メッセージ相手読み込み ////////
  ///////////////////////////////////
  const listListener = async () => {
    // messageコレクションを読み込む
    const MsgListCollectionRef = collection(db, "message");
    const qa = query(
      MsgListCollectionRef,
      where("to", "==", authInfo.uid),
      orderBy("timestamp")
    );
    const qb = query(
      MsgListCollectionRef,
      where("from", "==", authInfo.uid),
      orderBy("timestamp")
    );
    const queryASnapshot = await getDocs(qa);
    const queryBSnapshot = await getDocs(qb);
    let message_array: any = [];
    message_array = [
      queryASnapshot.docs.map((doc) => ({
        ...doc.data(),
        mmid: doc.id,
      })),
      queryBSnapshot.docs.map((doc) => ({
        ...doc.data(),
        mmid: doc.id,
      })),
    ];
    // message_array[0]とmessage_array[1]を結合する
    let Msg_array = message_array[0].concat(message_array[1]);
    // To,Fromの情報だけ抜き出し、member_arrayに格納
    let member_array: any = [];
    Msg_array.map((doc: any) => {
      member_array.push(doc.to);
      member_array.push(doc.from);
    });
    let member_set: any = new Set(member_array); // 重複をなくす(Set)
    let member = Array.from(member_set); // 配列型にする
    let user_array: any = [];
    member.map((uid: any) => {
      const userDocumentRef = doc(db, "users", uid || "");
      // StateにUidを入れる
      if (uid != authInfo.uid) {
        // 自分以外のUser情報を取得し、memberInfoに入れる
        getDoc(userDocumentRef).then((documentSnapshot) => {
          if (documentSnapshot.exists()) {
            user_array = [
              ...user_array,
              { ...documentSnapshot.data(), uid: uid },
            ];
            setMemberInfo(user_array);
          } else {
            console.log("No such User!");
          }
          return user_array;
        });
      }
    });
  };
  useEffect(() => {
    listListener();
  }, []);

  //////////////////////////////////
  ///////// 会話User情報取得 /////////
  //////////////////////////////////
  const [user, setUser] = useState<DocumentData>([]);
  const ReadUser = (uid: string) => {
    if (uid != undefined) {
      const userDocumentRef = doc(db, "users", uid);
      getDoc(userDocumentRef).then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          // const user = documentSnapshot.data();
          console.log("User Document data:", documentSnapshot.data());
          setUser(documentSnapshot.data());
        } else {
          console.log("No such User!");
        }
      });
    }
  };
  console.log("会話中のuser", user);
  ///////////////////////////////////
  ////////// Msg再読み込み //////////// ←メッセージ相手切り替え時に走る
  ///////////////////////////////////
  const ReadMsg = (uid: any) => {
    ReadUser(uid);
    const uid3_num = strigToNumber(uid);
    const mid = calcArray(uid3_num, uid2_num);
    setMsgNum(10);
    setOldMessages([]);
    UseMessageListener(mid, msgNum);
    if (mobileOpen == true) handleDrawerToggle();
    // 送信処理のためにメッセージの宛先を変更する(messageInfo.to)
    memberInfo.map((member: any) => {
      if (member.uid == uid) {
        setMessageInfo(member);
        // console.log("選ばれたmemberInfo", member);
      }
    });
    setMoreMsg(true);
  };

  ///////////////////////////////////
  ///////// リアルタイムMsg ///////////
  ///////////////////////////////////
  let now = Timestamp.fromDate(new Date()).toDate();
  // now.setSeconds(now.getSeconds() - 10);
  // console.log("before", now);
  const q = query(
    collection(db, "message"),
    where("mid", "==", mid),
    orderBy("timestamp", "desc"),
    endAt(now),
    limit(1)
  );

  let msg: any = [];
  // リアルタイムメッセージ開始
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    // querySnapshot.docChanges().forEach((doc) => {
    //   setMessages([
    //     {
    //       to: doc.doc.data().to,
    //       from: doc.doc.data().from,
    //       read: doc.doc.data().read,
    //       message: doc.doc.data().message,
    //       mid: doc.doc.data().mid,
    //       timestamp: doc.doc.data().timestamp,
    //       mmid: doc.doc.id,
    //     },
    //     ...messages,
    //   ]);
    // });
    querySnapshot.docChanges().forEach(async (doc) => {
      if (doc.type === "added") {
        const aaa: msgType = {
          to: doc.doc.data().to,
          from: doc.doc.data().from,
          read: doc.doc.data().read,
          message: doc.doc.data().message,
          mid: doc.doc.data().mid,
          timestamp: doc.doc.data().timestamp,
          mmid: doc.doc.id,
        };
        setNewMessages((prev) => {
          let nnn: any[] = [];
          if (newMessages.length != 0) {
            nnn = [aaa, ...newMessages];
          } else {
            nnn = [aaa];
          }
          // console.log("nnn", nnn);
          return nnn;
        });
        // msg.push({
        //   to: doc.doc.data().to,
        //   from: doc.doc.data().from,
        //   message: doc.doc.data().message,
        //   mid: doc.doc.data().mid,
        //   timestamp: doc.doc.data().timestamp,
        //   mmid: doc.doc.id,
        // });
      }
      const newMsgArray = msg.concat(newMessages);
      // setNewMessages(newMsgArray);
      // if (msg.length > 0) {
      // 追加時
      // setNewMessages(newMsgArray);

      // setNewMessages((prev) => {
      //   const ne: any = newMsgArray;
      //   console.log("ne", ne);
      //   return ne;
      // });

      // console.log("doc", newMessages);
      // }
      // console.log("msg", msg);
      // setNewMessages((prev) => {
      //   const newMsgArray = msg.concat(prev);
      //   return newMsgArray;
      // });
      // console.log("newMessages", newMessages);
    });
  });
  // useEffect(() => {
  // console.log("newMessages", newMessages); // 更新後のstateをコンソールに表示したい
  // }, [newMessages]);
  /////////////////////////////////////
  /////////// ドロワーの中身 ////////////
  /////////////////////////////////////
  console.log("memberInfo", memberInfo);
  const drawer = (
    <div>
      {memberInfo.map((memberInfo: any) => (
        <div key={memberInfo.uid}>
          <List>
            <ListItemButton onClick={() => ReadMsg(memberInfo.uid)}>
              <ListItemIcon>
                {memberInfo.userImageUrl != undefined ? (
                  <Avatar src={memberInfo.userImageUrl} />
                ) : (
                  <Avatar src='https://picsum.photos/200/300' />
                )}
              </ListItemIcon>
              <ListItemText primary={memberInfo.name} />
            </ListItemButton>
          </List>
        </div>
      ))}
    </div>
  );

  interface Props {
    window?: () => Window;
  }
  function setContainer(prop: Props) {
    const { window } = prop;
    const container =
      window !== undefined ? () => window().document.body : undefined;
    return container;
  }
  const container = setContainer(prop);

  ///////////////////////////////////
  //////////// Msg送信 //////////////
  ///////////////////////////////////
  const sendMsg = async (event: {
    [x: string]: any;
    preventDefault: () => void;
  }) => {
    ScrollToBottom();
    event.preventDefault();
    const { message } = event.target.elements;
    const Msg = message.value;
    message.value = "";
    const messageRef = collection(db, "message");
    // let now = Timestamp.fromDate(new Date());
    // console.log("serberTimestamp", now);
    // let nowString = now;
    // console.log("nowString", nowString);
    if (Msg !== "") {
      await addDoc(messageRef, {
        message: Msg,
        from: authInfo.uid,
        to: messageInfo.uid,
        read: false,
        mid: mid,
        timestamp: now,
      });
      const msgInfo = {
        message: Msg,
        from_name: authInfo.name,
        to_name: user.name,
        to_email: user.email,
      };
      sendMailFunc(msgInfo);
    }
  };

  ///////////////////////////////////
  /// 開いた時最下部までスクロールする ///
  ///////////////////////////////////
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const ScrollToBottom = () => {
    // if (messages.length < 30) {
    messagesEndRef.current?.scrollIntoView();
    // console.log("scrollToBottom実行");
    // }
  };
  // setTimeout( () => {}, 1500);
  useEffect(ScrollToBottom, [messages]);
  ///////////////////////////////////
  /////// ページ最上部でトリガー ////////
  ///////////////////////////////////
  ////// メッセージを追加読み込み ///////
  ///////////////////////////////////
  // window.addEventListener("scroll", handleScroll);
  const loadMsg = async () => {
    // setTimeout(async () => {
    //   console.log("loadMsgはいった");
    //   // useReadMore(mid, msgNum);
    //   let msgBefore = messages;
    //   // messageコレクションを読み込む
    //   const MsgCollectionRef = collection(db, "message");
    //   // startAfterに入れる変数を用意する
    //   let startPoint = Timestamp.fromDate(new Date()); //現在時刻
    //   switch (oldMessages.length) {
    //     case 0:
    //       console.log(`あああ${startPoint}`);
    //       if (messages.length != 0)
    //         startPoint = messages[messages.length - 1].timestamp;
    //       break;
    //     default:
    //       console.log(`いいい${oldMessages}`);
    //       if (oldMessages.length != 0)
    //         startPoint = oldMessages[oldMessages.length - 1].timestamp;
    //       break;
    //   }
    //   console.log("startPoint", startPoint);
    //   const q = query(
    //     MsgCollectionRef,
    //     where("mid", "==", mid),
    //     orderBy("timestamp", "desc"),
    //     startAfter(startPoint),
    //     limit(5)
    //   );
    //   const querySnapshot = await getDocs(q);
    //   let message: any = [];
    //   message = oldMessages;
    //   querySnapshot.docs.map((doc) => {
    //     message.push({
    //       // ...doc.data(),
    //       to: doc.data().to,
    //       from: doc.data().from,
    //       message: doc.data().message,
    //       mid: doc.data().mid,
    //       timestamp: doc.data().timestamp,
    //       mmid: doc.id,
    //     });
    //     console.log("setOldMessagesに入れる", message);
    //     setOldMessages(message);
    //   });
    //   setMsgNum(msgNum + 5); // 使われてない気がする
    // }, 3000);
    // const hasMore = checkHasMore();
    // console.log("hasMore", hasMore);
  };
  const [moreMsg, setMoreMsg] = useState(true);

  const checkHasMore = async () => {
    let msg: any = "aaaaaa";
    const MsgCollectionRef = collection(db, "message");
    const qc = query(
      MsgCollectionRef,
      where("mid", "==", mid),
      orderBy("timestamp", "asc"),
      limit(1)
    );
    const querySnapshot = await getDocs(qc);
    querySnapshot.docs.map((doc) => {
      // console.log("msgに入れるデータ", doc.data());
      // console.log("msgに入れるデータ", doc.id);
      msg = doc.id;
    });
    // console.log("msg", msg);
    if (messages.length > 0) {
      if (msg == messages[messages.length - 1].mmid) {
        setMoreMsg(false);
      }
    }
  };
  // console.log("hasMore", moreMsg);

  ///////////////////////////////////
  ///////////// return //////////////
  ///////////////////////////////////
  return (
    <div className='App-content'>
      <Toolbar />
      <Box
        sx={{
          top: 60,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            mt: 1,
            backgroundColor: "secondary.light",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mx: 0, display: { sm: "none" } }}
          >
            <ArrowBackIosNewIcon />
            <Typography
              component='p'
              sx={{
                my: 0,
                mx: 4,
                color: "primary.light",
                fontSize: 20,
              }}
            >
              {user.name}
            </Typography>
          </IconButton>
        </Box>
        <Box
          component='nav'
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label='mailbox folders'
        >
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              mt: 10,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                mt: 7,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant='permanent'
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                mt: 8,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Box
            sx={{
              position: "fixed",
              top: 65,
              left: { sm: `${drawerWidth}px` },
              mt: 1,
              backgroundColor: "secondary.light",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <Typography
              component='p'
              sx={{
                py: 1,
                px: 1,
                width: "auto",
                color: "primary.light",
                display: { xs: "none", sm: "block" },
                // backgroundColor: "secondary.light",
                fontSize: 20,
              }}
            >
              {user.name}
            </Typography>
          </Box>
          {/* メッセージ表示 */}
          <Box sx={{ mb: 5, pb: 3 }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMsg}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              hasMore={moreMsg}
              isReverse={true}
            >
              {
                //////////////////////////////////
                ///////////// 未来Msg /////////////
                //////////////////////////////////
              }
              {newMessages.map((message) => (
                // const date=dayjs(message.timestamp.toDate());
                <div key={message.mmid}>
                  {message.from == authInfo.uid ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box
                        component='div'
                        sx={{
                          display: "inline-block",
                          maxWidth: "80%",
                        }}
                      >
                        <Typography
                          component='p'
                          sx={{
                            borderRadius: "25px",
                            backgroundColor: "secondary.dark",
                            px: 2,
                            color: "primary.light",
                            fontSize: 20,
                          }}
                        >
                          {message.message}
                        </Typography>
                        <Typography
                          variant='caption'
                          sx={{
                            color: "gray",
                            display: "flex",
                            justifyContent: "flex-end",
                            mr: 2,
                          }}
                        >
                          {message.timestamp?.toDate().toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: "inline-block", maxWidth: "80%" }}>
                      <Typography
                        component='p'
                        sx={{
                          borderRadius: "25px",
                          backgroundColor: "primary.dark",
                          px: 2,
                          color: "primary.light",
                          fontSize: 20,
                        }}
                      >
                        {message.message}
                      </Typography>
                      <Typography
                        variant='caption'
                        sx={{
                          color: "gray",
                          display: "flex",
                          justifyContent: "flex-end",
                          mr: 2,
                        }}
                      >
                        {message.timestamp?.toDate().toLocaleString()}
                      </Typography>
                    </Box>
                  )}
                </div>
              ))}
              {
                //////////////////////////////////
                ///////////// 初回Msg /////////////
                //////////////////////////////////
              }
              {messages.map((message) => (
                // const date=dayjs(message.timestamp.toDate());
                <div key={message.mmid}>
                  {message.from == authInfo.uid ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Box sx={{ display: "inline-block", maxWidth: "80%" }}>
                        <Typography
                          component='p'
                          sx={{
                            borderRadius: "25px",
                            backgroundColor: "secondary.dark",
                            px: 2,
                            color: "primary.light",
                            fontSize: 20,
                          }}
                        >
                          {message.message}
                        </Typography>
                        <Typography
                          variant='caption'
                          sx={{
                            color: "gray",
                            display: "flex",
                            justifyContent: "flex-end",
                            mr: 2,
                          }}
                        >
                          {message.timestamp?.toDate().toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={{ display: "inline-block", maxWidth: "80%" }}>
                      <Typography
                        component='p'
                        sx={{
                          borderRadius: "25px",
                          backgroundColor: "primary.dark",
                          px: 2,
                          color: "primary.light",
                          fontSize: 20,
                        }}
                      >
                        {message.message}
                      </Typography>
                      <Typography
                        variant='caption'
                        sx={{
                          color: "gray",
                          display: "flex",
                          justifyContent: "flex-end",
                          mr: 2,
                        }}
                      >
                        {message.timestamp?.toDate().toLocaleString()}
                      </Typography>
                    </Box>
                  )}
                </div>
              ))}
              {
                //////////////////////////////////
                ///////////// 過去Msg /////////////
                //////////////////////////////////
              }
              {/* {oldMessages.map((message) => (
              <div key={message.mmid}>
                {message.from == authInfo.uid ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box sx={{ display: "inline-block", maxWidth: "80%" }}>
                      <Typography
                        component='p'
                        sx={{
                          borderRadius: "25px",
                          backgroundColor: "secondary.dark",
                          px: 2,
                          color: "primary.light",
                          fontSize: 20,
                        }}
                      >
                        {message.message}
                      </Typography>
                      <Typography
                        variant='caption'
                        sx={{
                          color: "gray",
                          display: "flex",
                          justifyContent: "flex-end",
                          mr: 2,
                        }}
                      >
                        {message.timestamp?.toDate().toLocaleString()}{" "}
                        (過去メッセージ)
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ display: "inline-block", maxWidth: "80%" }}>
                    <Typography
                      component='p'
                      sx={{
                        borderRadius: "25px",
                        backgroundColor: "primary.dark",
                        px: 2,
                        color: "primary.light",
                        fontSize: 20,
                      }}
                    >
                      {message.message}
                    </Typography>
                    <Typography
                      variant='caption'
                      sx={{
                        color: "gray",
                        display: "flex",
                        justifyContent: "flex-end",
                        mr: 2,
                      }}
                    >
                      {message.timestamp?.toDate().toLocaleString()}{" "}
                      (過去メッセージ)
                    </Typography>
                  </Box>
                )}
              </div>
            ))} */}
            </InfiniteScroll>
          </Box>
          <div ref={messagesEndRef} />
          {/* 送信フォーム */}
          <form onSubmit={sendMsg} name='Msgform'>
            <Box
              position='fixed'
              sx={{
                // width: "100%",
                width: { xs: "97%", sm: `calc(97% - ${drawerWidth}px)` },
                height: "80px",
                display: "flex",
                pb: 2,
                mt: 5,
                top: "auto",
                bottom: 0,
                backgroundColor: "#282c34",
              }}
            >
              {/* <Box
                position='fixed'
                sx={{
                  width: 500,
                  maxWidth: "96%",
                  display: "flex",
                  pb: 2,
                  mt: 5,
                  top: "auto",
                  bottom: 0,
                  backgroundColor: "#282c34",
                }}
              > */}
              <TextField
                name='message'
                type='message'
                fullWidth
                // autoFocus
                sx={{
                  border: "1px solid #e2e2e1",
                  borderRadius: 3,
                  borderColor: "#e2e2e1",
                }}
                id='fullWidth'
              />
              <Button type='submit'>
                <SendIcon sx={{ color: "primary.light" }} />
              </Button>
              {/* </Box> */}
            </Box>
          </form>
        </Box>
      </Box>
    </div>
  );
}
