import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import { db } from "components/firebase-config";
import {
  doc,
  getDoc,
  collection,
  DocumentData,
  deleteDoc,
  query,
  getDocs,
  setDoc,
  where,
  orderBy,
  QuerySnapshot,
  limit,
  Timestamp,
} from "firebase/firestore";
import React, { Key, ReactNode, useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Button, Stack, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Image } from "mui-image";
import { Ratio } from "react-bootstrap";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EditIcon from "@mui/icons-material/Edit";
import Board from "components/pages/parts/posts/board";
import ShareSNS from "components/pages/parts/share";
import Head from "components/Head";

const PostDetail = (props: any) => {
  // console.log("postDetail", "はいった");
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);

  useEffect(() => {
    readProject();
    readRecentPost();
    // 表示時に最上部を表示する
    window.scrollTo(0, 0);
  }, []);
  // 単一ページ表示

  /////////////////////////////////////
  ///////////// ボタン /////////////////
  /////////////////////////////////////

  /////////////////////////////////////
  ///////// プロジェクト取得 /////////////
  /////////////////////////////////////
  const [posts, setPosts] = useState<DocumentData>([]);
  const navigate = useNavigate();

  const params = useParams();
  // console.log("postDetail-pid", params.pid);
  const pid: string = params.pid || ""; // undefinedの場合の処理を記載する必要がある
  const readProject = () => {
    const postDocumentRef = doc(db, "posts", pid);
    getDoc(postDocumentRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        const post = documentSnapshot.data();
        console.log("Document data:", documentSnapshot.data());
        const postuid: string = post.uid;
        // console.log("postDetailUid", postuid);
        setPosts(documentSnapshot.data());
        readPostUser(postuid);
      } else {
        console.log("No such document!");
      }
    });
  };

  /////////////////////////////////////
  /////// SlideShare埋め込み ///////////
  /////////////////////////////////////
  const Embed: any = () => {
    // if (posts.slideShere != undefined) {
    if (
      posts.slideShere != undefined &&
      posts.slideShere.includes("slideshare")
    ) {
      var splitResult = posts.slideShere.split(" ");
      var splitFind = splitResult.find((value: string) => value.match(/src=/g));
      var result = splitFind.replace('src="', "").replace('"', "");
      console.log("result", result);

      return (
        <Ratio aspectRatio='4x3'>
          <iframe
            src={result}
            width='100%'
            height='100%'
            scrolling='no'
          ></iframe>
        </Ratio>
      );
    } else {
    }
    // } else {
    // }
  };
  /////////////////////////////////////
  ////////// PostUser取得 //////////////
  /////////////////////////////////////
  const [postUser, setPostUser] = useState<DocumentData>([]);
  // const [postUser, setPostUser] = useState<string>([]);
  const readPostUser = async (
    postuid: string
    // querySnapshot: QuerySnapshot<DocumentData>
  ) => {
    // console.log("Detail - readPostUserに入ってる", postuid);
    const PostUserDocumentRef = doc(db, "users", postuid);
    getDoc(PostUserDocumentRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        console.log("Detail user data:", documentSnapshot.data());
        setPostUser(documentSnapshot.data());
      }
    });
  };

  // Delete
  const handleDelete = async () => {
    if (window.confirm("プロジェクトを完全に削除しますか？")) {
      // await deleteDoc(ref);
      await deleteDoc(doc(db, "posts", pid));
      navigate("/posts");
    } else {
      alert("cancel");
    }
  };

  // const projectURL = window.location.href;
  const moveWithData = async (path: any) => {
    navigate(path, {
      state: {
        imageUrl: posts.imageUrl,
        title: posts.title,
        content: posts.content,
        slideShere: posts.slideShere,
        email: posts.email,
        pid: params.pid,
        category: "project",
        industry: posts.industry,
        employment: posts.employment,
        investment: posts.investment,
        investmentMoney: posts.investmentMoney,
        uid: authInfo.uid,
        url: window.location.href,
      },
    });
  };

  // 戻るボタン
  const handleBack = async () => {
    navigate(-1);
  };

  ///////////////////////////////////
  ////////////// Tab設定 /////////////
  ///////////////////////////////////
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  ///////////////////////////////////
  ///////////// 活動報告処理 //////////
  ///////////////////////////////////
  const readRecentPost = async () => {
    // console.log("readRecentPostにはいった");
    const PostsCollectionRef = collection(db, "posts", pid, "tweet");
    const q = query(
      PostsCollectionRef,
      // where("category", "==", "recentPost"),
      orderBy("updateDate", "desc"),
      limit(5)
    );
    const querySnapshot = await getDocs(q);
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                tid: posts_doc.id,
              },
            ];
            setRecentPosts(post_array);
          }
        );
      })
    );
  };

  const [recentPosts, setRecentPosts] = useState<
    {
      pid: Key;
      category: string;
      title: string;
      content: string;
      email: string;
      uid: string;
      tid: string;
      createDate: Timestamp;
      updateDate: Timestamp;
    }[]
  >([]);

  /////////////////////////////////////////
  /////////// Componentに渡す情報 ///////////
  /////////////////////////////////////////
  const data = {
    title: posts.title,
    content: posts.content,
    image: posts.imageUrl,
    url: window.location.href,
    type: "article",
  };
  // const title: string = "aaaああああ";
  // console.log("postDetail - postUser.idです", posts.uid);

  const boardData = {
    firstId: params.pid, //postid
    firstCollection: "posts",
  };

  return (
    <>
      {/* <Head data={data} /> */}
      <div
        style={{
          width: "100%",
          maxWidth: "1000px",
          margin: "0",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className='App-content'>
          <Box sx={{ mt: 15, mb: 5, mx: 2 }}>
            {/* <Grid container> */}
            <Ratio aspectRatio='16x9'>
              <Image
                src={posts.imageUrl}
                alt={posts.title + "のサムネイル"}
                fit='contain'
                duration={0}
              />
            </Ratio>
            <Typography variant='h3' component='h2' sx={{ mt: 3 }}>
              {posts.title}
            </Typography>
            <Grid container sx={{ mb: 5 }}>
              <Grid item xs='auto'>
                <Box>
                  <>
                    {posts.industry?.map((industry: any) => (
                      <Chip
                        label={industry}
                        size='small'
                        sx={{ mt: 1, mr: 0.5 }}
                        color='secondary'
                        variant='outlined'
                      />
                    ))}
                  </>
                </Box>

                {/* <div key={posts.pid}> */}
                <Box sx={{ mt: 1, ml: 1 }}>
                  <Typography
                    variant='caption'
                    component='div'
                    color='primary.light'
                  >
                    {posts.investment != undefined
                      ? posts.investment
                          .replace("true", "投資受付中")
                          .replace("false", "投資受付停止中")
                      : ""}
                    {posts.investmentMoney != ""
                      ? ` : ${posts.investmentMoney}`
                      : ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs />
              <Grid item xs='auto' sx={{ textAlign: "right" }}>
                <NavLink to={`/user/${posts.uid}`}>
                  <Box
                    sx={{
                      border: 1,
                      borderColor: "primary.light",
                      p: 1,
                      mt: 2,
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant='h6'
                      component='div'
                      color='primary.light'
                    >
                      {postUser.name}
                    </Typography>
                  </Box>
                </NavLink>
              </Grid>
            </Grid>
            <Box>
              <Typography
                variant='caption'
                component='div'
                color='primary.light'
              >
                プロジェクトをシェアする
              </Typography>
              <ShareSNS url={window.location.href} data={data} />
            </Box>
            <Box sx={{ textAlign: "right", mb: 2 }}>
              <Typography
                variant='caption'
                component='div'
                color='primary.light'
              >
                投稿日：
                {posts.createDate?.toDate().toLocaleString("ja-JP", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </Typography>
            </Box>
            <Typography variant='body1' mb={3} sx={{ fontWeight: "bold" }}>
              {posts.content}
            </Typography>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList
                  onChange={handleChange}
                  variant='fullWidth'
                  aria-label='lab API tabs example'
                  textColor='inherit'
                >
                  <Tab
                    label='活動内容'
                    value='1'
                    sx={{
                      bgcolor: "primary.main",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  />
                  <Tab
                    label='活動報告'
                    value='2'
                    sx={{
                      bgcolor: "primary.main",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  />
                  <Tab
                    label='募集職種'
                    value='3'
                    sx={{
                      bgcolor: "primary.main",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <Embed />
                <Typography variant='body1' mb={3} sx={{ mt: 5 }}>
                  {posts.content}
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    variant='contained'
                    sx={{
                      mx: { xs: 0, md: 2 },
                      my: 1,
                      width: { xs: "80%", md: "60%" },
                      maxWidth: "700px",
                      height: 50,
                      borderRadius: "25px",
                      backgroundColor: "secondary.light",
                      color: "primary.light",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    startIcon={
                      <PublishIcon
                        fontSize='large'
                        style={{ color: "inherit" }}
                      />
                    }
                    onClick={() => moveWithData("/recentpostcreate")}
                  >
                    活動報告
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value='2'>
                <Typography variant='h5' mb={3} sx={{ fontWeight: "bold" }}>
                  活動報告
                </Typography>
                {recentPosts.map((recentPost) => (
                  <Card
                    sx={{
                      minWidth: 275,
                      backgroundColor: "primary.main",
                      my: 2,
                    }}
                  >
                    <CardContent>
                      <div key={recentPost.pid}>
                        <Typography
                          variant='caption'
                          component='div'
                          color='primary.light'
                        >
                          {/* {recentPost.createDate} */}
                          {recentPost.createDate
                            ?.toDate()
                            .toLocaleString("ja-JP", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                        </Typography>
                        <Typography
                          variant='h5'
                          component='div'
                          color='primary.light'
                        >
                          {recentPost.title}
                        </Typography>
                        <Typography color='primary.light'>
                          {recentPost.content}
                        </Typography>
                        <Grid container sx={{ mt: 1 }}>
                          <Grid item xs='auto'>
                            <ShareSNS
                              url={window.location.href}
                              data={recentPost}
                            />
                          </Grid>
                          <Grid item xs />
                          <Grid item xs='auto'>
                            <Button
                              onClick={() =>
                                moveWithData(`/recentpost/${recentPost.tid}`)
                              }
                              sx={{ color: "white", pb: 0 }}
                            >
                              コメントを見る
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                ))}
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    variant='contained'
                    sx={{
                      mx: { xs: 0, md: 2 },
                      my: 1,
                      width: { xs: "80%", md: "60%" },
                      maxWidth: "700px",
                      height: 50,
                      borderRadius: "25px",
                      backgroundColor: "secondary.light",
                      color: "primary.light",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    startIcon={
                      <PublishIcon
                        fontSize='large'
                        style={{ color: "inherit" }}
                      />
                    }
                    onClick={() => moveWithData("/recentpostcreate")}
                  >
                    活動報告
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value='3'>
                <Typography variant='h5' mb={3} sx={{ fontWeight: "bold" }}>
                  募集職種
                </Typography>
                {posts.employment?.map((emp: any) => (
                  <Card
                    sx={{
                      minWidth: 275,
                      backgroundColor: "primary.main",
                      my: 2,
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant='h6'
                        component='div'
                        color='primary.light'
                      >
                        {emp.job.join("、")}
                      </Typography>
                      <Typography
                        variant='body1'
                        component='div'
                        color='primary.light'
                      >
                        {emp.detail}
                      </Typography>
                      <Grid container sx={{ mt: 3 }}>
                        <Grid item xs />
                        <Grid item xs={"auto"}>
                          <Box>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              color='primary.light'
                            >
                              {emp.weekly}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              color='primary.light'
                            >
                              期間：{emp.days} {emp.unit}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              color='primary.light'
                            >
                              報酬：{emp.reward}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </TabPanel>
            </TabContext>

            {/* コメント */}
            <Box sx={{ height: 100 }} />
            <Board data={boardData} />
            {/* ボタン系 */}
            {/* <Stack direction='row' spacing={2}> */}
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  // variant='outlined'
                  color='inherit'
                  startIcon={<BackspaceIcon style={{ color: "inherit" }} />}
                  onClick={() => handleBack()}
                >
                  戻る
                </Button>
              </Grid>
              <Grid item xs />
              {authInfo.uid == posts.uid ? (
                <>
                  <Grid item xs='auto'>
                    <Button
                      variant='outlined'
                      color='inherit'
                      startIcon={<EditIcon style={{ color: "inherit" }} />}
                      onClick={() => moveWithData("/posts/editproject")}
                    >
                      編集
                    </Button>
                  </Grid>
                  <Grid item xs='auto'>
                    <Button
                      variant='outlined'
                      // color='#ff7171'
                      sx={{ color: "#ff7171", borderColor: "#ff7171" }}
                      startIcon={<DeleteIcon style={{ color: "#ff7171" }} />}
                      onClick={() => handleDelete()}
                    >
                      削除
                    </Button>
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
            {/* </Stack> */}
          </Box>
        </div>
      </div>
    </>
  );
};
export default PostDetail;
