import { useState, useEffect, ReactNode } from "react";
import { db } from "components/firebase-config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  Box,
  Card,
  CardContent,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  Link,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

const PostList: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [category, setCategory] = useState<any>(location.state || "");
  // const category = location.state as string;
  console.log("PostListに入った、カテゴリー", category.category);
  const [posts, setPosts] = useState<
    {
      [x: string]: ReactNode;
      pid: string;
    }[]
  >([]);
  const [documents, setDocuments] = useState<{ pid: string }[]>([]);
  useEffect(() => {
    const readPosts = async () => {
      // Read
      const PostsCollectionRef = collection(db, "posts");
      const q = query(
        PostsCollectionRef,
        where("category", "==", category.category),
        orderBy("updateDate", "desc")
      );
      const querySnapshot = await getDocs(q);
      setPosts(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          pid: doc.id,
        }))
      );
    };
    readPosts();
  }, []);
  return (
    <div>
      <div className='App-content'>
        <Typography variant='h1' component='h2' mt={20}>
          投稿一覧
        </Typography>
        {posts.map((post) => (
          <Card
            sx={{
              width: 11 / 12,
              minWidth: 275,
              backgroundColor: "primary.main",
              my: 2,
              mx: "auto",
            }}
          >
            <CardContent>
              <div key={post.pid}>
                <NavLink to={`/posts/${post.pid}`}>
                  <Typography
                    variant='h5'
                    component='div'
                    color='primary.light'
                  >
                    {post.pid}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color='primary.light'>
                    {post.content}
                  </Typography>
                  <Typography variant='body2' color='primary.light'>
                    {post.name}
                  </Typography>
                  <Typography variant='body2' color='primary.light'>
                    {post.pid}
                  </Typography>
                </NavLink>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default PostList;
