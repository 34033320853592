import { createTheme } from "@mui/material/styles";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#009688",
//     },
//   },
// });

const theme = createTheme({
  palette: {
    primary: {
      main: "#403F3F", //濃いグレー
      light: "#F8F8F8", //しろ
      dark: "#525050", //薄いグレー
    },
    secondary: {
      main: "#D07D00", //きいろ
      dark: "#935FBB", // むらさき
      light: "#ff6b00", //濃いオレンジ
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "sans-serif",
      "Yu Gothic",
      "Roboto",
      "sans-serif",
    ].join(","),
    fontSize: 14,
  },
});

export default theme;
