import React, { ReactNode, useContext, useEffect, useState } from "react";
import { auth, db, storage } from "components/firebase-config";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { NavLink, To, useNavigate, useParams } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
import LogoutIcon from "@mui/icons-material/Logout";
import orangebg from "components/assets/image/orange.png";
import defaultImg from "components/assets/image/ROMEO-logo.jpg";
import { Image } from "mui-image";
import { Ratio } from "react-bootstrap";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { getDownloadURL, ref } from "firebase/storage";

const Mypage: React.FC = () => {
  // const auser = auth.currentUser;
  const [users, setUsers] = useState<DocumentData>([]);
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  const params = useParams();

  /////////////////////////////////////
  /////////// 細かい処理 ///////////////
  /////////////////////////////////////
  // 表示時に最上部を表示する
  window.scrollTo(0, 0);

  useEffect(() => {
    // authCheckerは動作してないと思うから消していいかも
    // const authChecker = async () => {
    //   if (authInfo.isLogin == false) {
    //     navigate("/login");
    //   }
    // };
    // authChecker();

    const userDocumentRef = doc(db, "users", params.uid || "");
    getDoc(userDocumentRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        // const user = documentSnapshot.data();
        console.log("User Document data:", documentSnapshot.data());
        setUsers(documentSnapshot.data());
      } else {
        console.log("No such User!");
      }
    });
    readProject();
  }, []);

  ///////////////////////////////////////
  ////// queryで有識者依頼Postを取得 ///////
  ///////////////////////////////////////
  const [projects, setProjects] = useState<
    {
      [x: string]: ReactNode;
      pid: string;
    }[]
  >([]);

  const readProject = async () => {
    console.log("readProjectにはいった", authInfo.uid);
    const PostsCollectionRef = collection(db, "posts");
    const q = query(
      PostsCollectionRef,
      where("category", "==", "project"),
      where("uid", "==", params.uid),
      orderBy("updateDate", "desc"),
      limit(10)
    );

    const querySnapshot = await getDocs(q);
    console.log("取得したデータ", querySnapshot);
    // post_arrayに入れてからsetStateするパターン
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                pid: posts_doc.id,
              },
            ];
            setProjects(post_array);
          }
        );
      })
    );
  };
  ///////////////////////////////////////
  /////////////// Delete ////////////////
  ///////////////////////////////////////
  const handleDelete = async () => {
    if (window.confirm("ユーザーを完全に削除しますか？")) {
      await deleteDoc(doc(db, "users", authInfo.uid));
      navigate("/userlist");
    } else {
      alert("cancel");
    }
  };

  ///////////////////////////////////////
  //////////////// Edit /////////////////
  ///////////////////////////////////////
  const moveWithUserInfo = async (props: To) => {
    navigate(props, {
      state: {
        userImageUrl: users.userImageUrl,
        name: users.name,
        role: users.role,
        email: users.email,
        comment: users.comment,
        uid: params.uid,
      },
    });
  };

  // 戻るボタン
  const handleBack = async () => {
    navigate(-1);
  };

  ///////////////////////////////////////
  /////// 関数「handleLogout」を定義///////
  ///////////////////////////////////////
  const HandleLogout = async (e: { preventDefault: () => void }) => {
    console.log("HandleLogout呼ばれたよ");
    e.preventDefault();
    try {
      //setcontext的なことをする
      setAuthInfo({
        email: "",
        isLogin: false,
        uid: "",
        name: "",
        userImageUrl: "https://picsum.photos/200/300",
        visited: false,
        role: "",
      });
      signOut(auth);
      navigate("/");
    } catch (error) {
      alert("ログアウトできていないよ");
    }
  };
  // console.log("これが自分のuid", authInfo.uid);
  // console.log("これが見たいユーザののuid", params.uid);
  console.log("Projectデータ", projects);

  ///////////////////////////////////////
  /////////////// return ////////////////
  ///////////////////////////////////////
  return (
    <>
      <div className='App-content mt-4'>
        <Stack
          alignItems='center'
          style={{
            // backgroundImage: `image-url('image/orange.png'})`,
            background: `linear-gradient(#f35d17, #9198e5)`,
          }}
          sx={{ pt: 5 }}
        >
          <Grid container sx={{ mb: 5 }}>
            <Grid item xs='auto'>
              <Button
                variant='text'
                color='inherit'
                startIcon={<ArrowBackIosOutlinedIcon />}
                onClick={() => handleBack()}
              />
            </Grid>
            <Grid item xs />
          </Grid>
          <Avatar src={users.userImageUrl} sx={{ width: 120, height: 120 }} />
          <Stack alignItems='center' spacing={2} sx={{ pt: 10, pb: 5 }}>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Typography variant='body1' color='primary.light'>
                {users.name}
              </Typography>
              <Chip
                label={users.role}
                size='small'
                variant='filled'
                sx={{
                  mt: 1,
                  mr: 0.5,
                  color: "primary.light",
                }}
              />
            </Stack>
            <Typography
              variant='caption'
              color='primary.light'
              sx={{ width: "70%" }}
            >
              {users.comment}
            </Typography>
          </Stack>
          <Grid container>
            <Grid item xs />
            <Grid item xs='auto'>
              {authInfo.isLogin == true ? (
                <>
                  {authInfo.uid == params.uid ? (
                    <Button
                      variant='text'
                      color='inherit'
                      sx={{ mr: 1 }}
                      onClick={() => moveWithUserInfo("/mypage/edit")}
                    >
                      プロフィールを編集
                    </Button>
                  ) : (
                    <Button
                      variant='text'
                      color='inherit'
                      startIcon={<ChatBubbleOutlineIcon />}
                      sx={{ mr: 1 }}
                      onClick={() => moveWithUserInfo("/message")}
                    >
                      メッセージ
                    </Button>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Stack>
        <Typography
          variant='h5'
          color='primary.light'
          sx={{ mx: 5, my: 10, fontWeight: "bold" }}
        >
          主催プロジェクト
        </Typography>
        <Container>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='flex-start'
            sx={{ maxWidth: "xl" }}
          >
            {projects.map((project: any) => (
              <Grid item xs={12} md={6} lg={4}>
                <Card
                  sx={{
                    minWidth: 275,
                    backgroundColor: "primary.main",
                    my: 2,
                    mx: 2,
                  }}
                >
                  <div key={project.pid}>
                    <NavLink to={`/posts/${project.pid}`}>
                      <Ratio aspectRatio='16x9'>
                        <CardMedia
                          component='img'
                          height='140'
                          image={project.imageUrl}
                          alt={project.title + "のサムネイル"}
                          sx={{ objectFit: "contain" }}
                        />
                      </Ratio>
                      <CardContent>
                        <Grid container sx={{ mb: 2 }}>
                          <Grid item xs='auto' sx={{ mx: 2 }}>
                            <Typography
                              variant='h5'
                              component='div'
                              color='primary.light'
                            >
                              {project.title}
                            </Typography>
                            <>
                              {project.industry?.map((industry: any) => (
                                <Chip
                                  label={industry}
                                  size='small'
                                  variant='outlined'
                                  sx={{
                                    my: 0,
                                    mr: 0.5,
                                    color: "primary.light",
                                  }}
                                />
                              ))}
                            </>
                            <>
                              {project.employment?.map((emp: any) => (
                                <>
                                  {emp.job.map((job: any) => (
                                    <Chip
                                      label={job}
                                      variant='outlined'
                                      size='small'
                                      sx={{
                                        mr: 0.5,
                                        color: "#5fbbe0",
                                        borderColor: "#5fbbe0",
                                      }}
                                    />
                                  ))}
                                </>
                              ))}
                            </>
                            <Chip
                              label={project.investment
                                .replace("true", "投資受付中")
                                .replace("false", "投資受付停止中")}
                              variant='outlined'
                              size='small'
                              sx={{
                                my: { xs: 0, md: -6, lg: -9 },
                                mr: 0.5,
                                color: "#ff7171",
                                borderColor: "#ff7171",
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Typography
                          variant='body2'
                          sx={{
                            color: "primary.light",
                            // mt: { xs: 0, md: -4 },
                            mt: -1,
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                          }}
                        >
                          {project.content}
                        </Typography>
                      </CardContent>
                    </NavLink>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        {authInfo.uid == params.uid ? (
          <Grid container sx={{ mt: 10, mb: 5 }}>
            <Grid item xs={"auto"} sx={{ ml: 2 }}>
              <Button
                variant='text'
                color='inherit'
                onClick={() => handleDelete()}
              >
                アカウント削除
              </Button>
            </Grid>
            <Grid item xs />
            <Grid item xs={"auto"} sx={{ mr: 2 }}>
              <Button
                variant='outlined'
                color='inherit'
                startIcon={<LogoutIcon style={{ color: "inherit" }} />}
                onClick={HandleLogout}
              >
                ログアウト
              </Button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Mypage;
