import {
  makeStyles,
  ThemeProvider,
  Theme,
  createStyles,
} from "@mui/material/styles";
import theme from "components/theme";
import Routers from "components/Routers";
import CssBaseline from "@mui/material/CssBaseline";
import "App.css";

// const useStyles = makeStyles({
//   toolbar: theme.mixins.toolbar,
//   ...theme.mixins.toolbar,
//   flexGrow: 1,
// });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routers />
      {/* <div className={classes.offset} /> */}
      <div className='App'></div>
      {/* <BottomNav /> */}
    </ThemeProvider>
  );
}

export default App;
