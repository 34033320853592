import { Card, CardContent, Container, Stack, Typography } from "@mui/material";
import { db } from "components/firebase-config";
import Board from "components/pages/parts/posts/board";
import ShareSNS from "components/pages/parts/share";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import { useState, useEffect, ReactNode, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

const BBSdetail: React.FC = () => {
  const params = useParams();

  /////////////////////////////////////
  ///////// スレッド取得 /////////////
  /////////////////////////////////////
  const [thread, setThread] = useState<DocumentData>([]);
  const navigate = useNavigate();

  const bid: string = params.bid || ""; // undefinedの場合の処理を記載する必要がある
  const readProject = () => {
    const postDocumentRef = doc(db, "thread", bid);
    getDoc(postDocumentRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        const post = documentSnapshot.data();
        console.log("setThread data:", documentSnapshot.data());
        const postuid: string = post.uid;
        // console.log("postDetailUid", postuid);
        setThread(documentSnapshot.data());
        readPostUser(postuid);
      } else {
        console.log("No such document!");
      }
    });
  };
  /////////////////////////////////////
  ////////// PostUser取得 //////////////
  /////////////////////////////////////
  const [postUser, setPostUser] = useState<DocumentData>([]);
  const readPostUser = async (postuid: string) => {
    const PostUserDocumentRef = doc(db, "users", postuid);
    getDoc(PostUserDocumentRef).then((documentSnapshot) => {
      if (documentSnapshot.exists()) {
        console.log("Detail user data:", documentSnapshot.data());
        setPostUser(documentSnapshot.data());
      }
    });
  };

  useEffect(() => {
    readProject();
    window.scrollTo(0, 0);
  }, []);

  /////////////////////////////////////////
  /////////// Componentに渡す情報 ///////////
  /////////////////////////////////////////
  const data = {
    title: thread.title,
    content: thread.content,
    url: window.location.href,
    type: "article",
  };
  const boardData = {
    firstId: params.bid, //postid
    firstCollection: "thread",
  };
  console.log("thread", thread);
  console.log("postUser", postUser);
  return (
    <div className='App-content mt-4'>
      <Container sx={{ mt: 10, maxWidth: "sm" }}>
        <Card
          sx={{
            minWidth: 275,
            backgroundColor: "primary.main",
            my: 2,
          }}
        >
          <CardContent>
            <div key={thread.bid}>
              <Typography
                variant='caption'
                component='div'
                color='primary.light'
              >
                {/* {recentPost.createDate} */}
                {thread.createDate?.toDate().toLocaleString("ja-JP", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </Typography>
              <Typography variant='h5' component='div' color='primary.light'>
                {thread.title}
              </Typography>
              <Typography color='primary.light'>{thread.content}</Typography>
              <ShareSNS url={window.location.href} data={data} />
            </div>
          </CardContent>
        </Card>
        <Board data={boardData} />
        {/* <Board postId={tempEdit.pid} /> */}
      </Container>
    </div>
  );
};

export default BBSdetail;
