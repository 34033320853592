import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  // Select,
  SelectChangeEvent,
  Stack,
  TextareaAutosize,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { db, storage } from "components/firebase-config";
import React, { useContext, useRef, useState } from "react";
import {
  SubmitHandler,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form";
import NumberFormat from "react-number-format";
import Select from "react-select";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  addDoc,
  collection,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import ImageLogo from "components/assets/image/image.svg";
import "components/assets/css/ImageUpload.css";
import { Image } from "mui-image";

// フォームの型
type FormInput = {
  imageUrl: any;
  title: string;
  content: string;
  slideShere: object;
  investment: string;
  investmentMoney: string;
  industry: string;
  employment: {
    job: string;
    detail: string;
    weekly: string;
    days: string;
    unit: string;
    reward: string;
  }[];
};

const CreateProject = (props: any) => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const navigate = useNavigate();
  const { register, handleSubmit, control, watch, setValue } =
    useForm<FormInput>({
      defaultValues: {
        employment: [
          {
            job: "",
            detail: "",
            days: "",
            weekly: "",
            reward: "",
            unit: "",
          },
        ],
        investment: "投資受付停止中",
        investmentMoney: "",
        imageUrl: "https://picsum.photos/200/300",
      },
    });

  //////////////////////////
  ///// プロジェクト作成 /////
  //////////////////////////
  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    // バリデーションチェックOK！なときに行う処理を追加
    let now = Timestamp.fromDate(new Date()).toDate();
    // console.log(data);
    // console.log(data.imageUrl);
    const postsCollectionRef = collection(db, "posts");
    await addDoc(postsCollectionRef, {
      imageUrl: image,
      content: data.content,
      title: data.title,
      slideShere: data.slideShere,
      email: authInfo.email,
      category: "project",
      industry: data.industry,
      employment: data.employment,
      investment: data.investment,
      investmentMoney: data.investmentMoney,
      uid: authInfo.uid,
      updateDate: now,
      createDate: now,
    });
    navigate("/");
  };
  // 動的フォーム用処理
  const { fields, append, remove } = useFieldArray({
    control,
    name: "employment",
    keyName: "key",
  });
  const addInputForm = () => {
    append({
      job: "",
      detail: "",
      weekly: "",
      days: "",
      unit: "",
      reward: "",
    });
  };
  //////////////////////////
  ////// Select Props //////
  //////////////////////////
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const industries = [
    { value: "IT", label: "IT" },
    { value: "飲食", label: "飲食" },
    { value: "金融", label: "金融" },
    { value: "小売", label: "小売" },
    { value: "メディア", label: "メディア" },
    { value: "メーカー", label: "メーカー" },
    { value: "その他", label: "その他" },
  ];
  const employments = [
    { value: "エンジニア", label: "エンジニア" },
    { value: "デザイナー", label: "デザイナー" },
    { value: "PjM", label: "PjM" },
    { value: "PdM", label: "PdM" },
    { value: "企画、経営", label: "企画、経営" },
    { value: "経理", label: "経理" },
    { value: "法務", label: "法務" },
    { value: "マーケティング", label: "マーケティング" },
  ];
  const unitOpt = [
    { value: "日間", label: "日間" },
    { value: "週間", label: "週間" },
    { value: "ヶ月間", label: "ヶ月間" },
    { value: "年間", label: "年間" },
  ];
  const investmentOpt = [
    { value: "true", label: "投資受付中" },
    { value: "false", label: "投資受付停止中" },
  ];
  let val = "";
  function getStyles(
    name: string,
    personName: readonly string[],
    theme: Theme
  ) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  //////////////////////////
  /////// 業界関連処理 ///////
  //////////////////////////
  const [industry, setIndustry] = useState<string[]>([]);
  let Industry = industry;
  const handleIndustryChange = (event: SelectChangeEvent<typeof industry>) => {
    const {
      target: { value },
    } = event;
    setIndustry(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    Industry = industry;
  };
  //////////////////////////
  ///// リクルート関連 ///////
  //////////////////////////
  const watchFieldArray = watch("employment");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  ////////////////////////////
  ///////// 画像表示 //////////
  ////////////////////////////
  const [image, setImage] = useState<string>(ImageLogo);

  ////////////////////////////
  ////// 画像アップロード ///////
  ////////////////////////////
  const OnFileUploadToFirebase = (e: any) => {
    // console.log(e.target.files[0].name);
    const file = e.target.files[0];
    const storageRef = ref(storage, "image/" + authInfo.uid + "/" + file.name);
    uploadBytes(storageRef, file).then((snapshot) => {
      console.log("Uploaded a file!");
      getDownloadURL(storageRef)
        .then((url) => {
          setImage(url);
        })
        .catch((err) => console.log(err));
    });
  };

  // 戻るボタン
  const handleBack = async () => {
    navigate(-1);
  };

  return (
    <div className='form'>
      <Container maxWidth='sm' sx={{ pt: 5, mt: 15 }}>
        <Stack spacing={3}>
          {
            ////////////////////////
            ///// 画像アップロード ////
            ////////////////////////
          }
          <div className='outerBox'>
            <div className='title'>
              <h2>画像をアップロード</h2>
              <p>jpg,jpeg,pngファイルに対応しています</p>
            </div>
            <div className='imageUplodeBox'>
              <div className='imageLogoAndText'>
                <Image
                  src={image}
                  alt='imagelogo'
                  fit='scale-down'
                  duration={0}
                />
                <p>{image == ImageLogo ? "ここにドラッグ＆ドロップ" : ""}</p>
              </div>
              <input
                className='imageUploadInput'
                multiple
                // ref={register}
                // name='imageURL'
                type='file'
                accept='.png, .jpeg, .jpg'
                onChange={OnFileUploadToFirebase}
                // {...register("imageURL")}
              />
            </div>
            <Button variant='contained' sx={{ mt: 3 }}>
              ファイルを選択
              <input
                className='imageUploadInput'
                type='file'
                accept='.png, .jpeg, .jpg'
                onChange={OnFileUploadToFirebase}
                // {...register("imageURL")}
              />
            </Button>
          </div>
          {
            ////////////////////////
            ///// プロジェクト名 /////
            ////////////////////////
          }
          <TextField
            required
            label='プロジェクト名'
            type='title'
            {...register("title", { required: true })}
          />
          {
            ////////////////////////
            //// プロジェクト概要 /////
            ////////////////////////
          }
          <Box>
            <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
              <InputLabel
                style={{ backgroundColor: "#fff", padding: "0 7px" }}
                shrink
              >
                プロジェクト概要
              </InputLabel>
              <TextareaAutosize
                required
                aria-label='minimum height'
                minRows={3}
                // placeholder="プロジェクト概要"
                {...register("content")}
              />
            </FormControl>
          </Box>
          {
            ////////////////////////
            ///// SlideShere /////
            ////////////////////////
          }
          <Box>
            <FormControl sx={{ width: "100%", maxWidth: "sm" }}>
              <InputLabel
                style={{ backgroundColor: "#fff", padding: "0 7px" }}
                shrink
              >
                SlideShare - Embed
              </InputLabel>
              <TextareaAutosize
                aria-label='SlideShare - Embed'
                minRows={3}
                {...register("slideShere")}
              />
            </FormControl>
            <Box style={{ fontSize: 14 }}>
              <Typography variant='caption' sx={{ mt: 0 }}>
                ※SlideShareの埋め込みコード(Embed)を入力してください。
              </Typography>
              <a target='_blank' href='https://aprico-media.com/posts/3765'>
                参考サイト
              </a>
            </Box>
          </Box>
          {
            ////////////////////////
            //////// 業界関連 ///////
            ////////////////////////
          }
          <Box sx={{ flexDirection: "row", flexWrap: "wrap" }}>
            <FormControl sx={{ width: 400 }}>
              <Controller
                name='industry'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <InputLabel
                      style={{ backgroundColor: "#fff", padding: "0 7px" }}
                      shrink
                    >
                      業界*
                    </InputLabel>
                    <Select
                      isMulti
                      options={industries}
                      value={industries.filter((x) =>
                        field.value?.includes(x.value)
                      )}
                      onChange={(newValue) => {
                        field.onChange(newValue.map((x) => x.value));
                      }}
                      placeholder=''
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </>
                )}
              />
            </FormControl>
          </Box>
          {
            ////////////////////////
            /////// リクルート //////
            ////////////////////////
          }
          {controlledFields.map((field, index) => {
            return (
              <div key={field.key}>
                <Box
                  sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    borderRadius: 2,
                    p: 2,
                    bgcolor: "#efefef",
                  }}
                >
                  <FormControl sx={{ width: 400 }}>
                    <Controller
                      name={`employment.${index}.job`}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <InputLabel
                            style={{
                              backgroundColor: "#fff",
                              padding: "0 7px",
                            }}
                            shrink
                          >
                            募集職種
                          </InputLabel>
                          <Select
                            isMulti
                            defaultValue={null}
                            options={employments}
                            value={employments.filter((x) =>
                              field.value?.includes(x.value)
                            )}
                            onChange={(newValue) => {
                              field.onChange(newValue.map((x) => x.value));
                            }}
                            placeholder=''
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ width: "100%", maxWidth: "sm", mt: 2 }}>
                    <InputLabel
                      style={{ backgroundColor: "#fff", padding: "0 7px" }}
                      shrink
                    >
                      募集詳細
                    </InputLabel>
                    <TextareaAutosize
                      // required
                      defaultValue={field.detail}
                      aria-label='minimum height'
                      minRows={2}
                      {...register(`employment.${index}.detail`)}
                    />
                  </FormControl>
                  <Grid container>
                    <FormControl sx={{ mt: 2, ml: 1, width: 150 }}>
                      <Controller
                        control={control}
                        name={`employment.${index}.weekly`}
                        render={({ field: { onChange, name, value } }) => (
                          <NumberFormat
                            customInput={TextField}
                            label='予定労働時間(週)'
                            InputLabelProps={{ shrink: true }}
                            size='small'
                            thousandSeparator={true}
                            prefix='週'
                            suffix='時間'
                            name={name}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <FormControl sx={{ mt: 2, ml: 1, width: 150 }}>
                    <TextField
                      type='number'
                      label='期間'
                      InputLabelProps={{ shrink: true }}
                      {...register(`employment.${index}.days` as const, {
                        valueAsNumber: true,
                      })}
                      size='small'
                    />
                  </FormControl>
                  <FormControl sx={{ width: 100, mt: 2, ml: 1 }}>
                    <Controller
                      name={`employment.${index}.unit`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <InputLabel
                            style={{
                              backgroundColor: "#fff",
                              padding: "0 7px",
                            }}
                            shrink
                          >
                            期間単位
                          </InputLabel>
                          <Select
                            options={unitOpt}
                            value={unitOpt.find((x) => x.value === field.value)}
                            onChange={(newValue) => {
                              field.onChange(newValue?.value);
                            }}
                            placeholder=''
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  <FormControl sx={{ mt: 2, ml: 1, width: 180 }}>
                    <Controller
                      control={control}
                      name={`employment.${index}.reward`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          customInput={TextField}
                          label='報酬'
                          InputLabelProps={{ shrink: true }}
                          size='small'
                          thousandSeparator={true}
                          // thousandsGroupStyle="thousand"
                          suffix='円'
                          name={name}
                          value={value}
                          onChange={onChange}
                          placeholder='未入力状態で「未定」'
                        />
                      )}
                    />
                  </FormControl>
                  {/* クリックで行を削除するIconButtonを2行目から表示 */}
                  {index > 0 && (
                    <Stack>
                      <IconButton
                        color='inherit'
                        onClick={() => remove(index)}
                        // size="small"
                        sx={{ mt: 2 }}
                      >
                        <ClearOutlinedIcon />
                      </IconButton>
                    </Stack>
                  )}
                </Box>
              </div>
            );
          })}
          <Button
            color='primary'
            variant='contained'
            size='small'
            onClick={() =>
              // クリックで行が増える
              // 増やした行の初期値を設定
              append({
                job: "",
                detail: "",
                weekly: "",
                days: "",
                unit: "",
                reward: "",
              })
            }
            // leftIcon={<AddIcon />}
          >
            行を追加
          </Button>
          {
            ////////////////////////
            //////// 投資関連 ///////
            ////////////////////////
          }
          <Box sx={{ flexDirection: "row", flexWrap: "wrap" }}>
            <FormControl sx={{ width: 200, ml: 1, mt: 3 }}>
              <Controller
                name='investment'
                control={control}
                render={({ field }) => (
                  <>
                    <InputLabel
                      style={{ backgroundColor: "#fff", padding: "0 7px" }}
                      shrink
                    >
                      投資可否
                    </InputLabel>
                    <Select
                      options={investmentOpt}
                      value={investmentOpt.find((x) => x.value === field.value)}
                      onChange={(newValue) => {
                        field.onChange(newValue?.value);
                      }}
                      placeholder='投資受付停止中'
                    />
                  </>
                )}
              />
            </FormControl>

            <FormControl sx={{ ml: 1, mt: 3, width: 160 }}>
              <Controller
                control={control}
                name='investmentMoney'
                render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                    customInput={TextField}
                    label='金額'
                    placeholder='0万円'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    thousandSeparator={true}
                    // thousandsGroupStyle="thousand"
                    suffix='万円'
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
          </Box>
        </Stack>
        {
          ////////////////////////
          //////// Submit ////////
          ////////////////////////
        }
        <Stack spacing={3} sx={{ my: 10 }}>
          <Button
            color='secondary'
            variant='contained'
            size='large'
            onClick={handleSubmit(onSubmit)}
          >
            作成
          </Button>
          <Button
            variant='outlined'
            color='inherit'
            onClick={() => handleBack()}
          >
            戻る
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default CreateProject;
