import * as React from "react";

// importing material UI components
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/ListItem";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { auth, db } from "components/firebase-config";
import { useContext, useEffect, useState } from "react";
import pcLogo from "components/assets/image/ROMEO-header-logo.png";
import {
  // LoggedInContext,
  AuthInfoContext,
} from "../context/AuthContextProvider";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { Navigate, NavLink, To, useNavigate } from "react-router-dom";
import PostDetail from "components/pages/posts/project/projectDetail";
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

export interface ILogoutPageProps {}
export const TopNav: React.FunctionComponent<ILogoutPageProps> = (props) => {
  const user = auth.currentUser;
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  // console.log("Topnav", { user });
  // console.log("Topnav_authInfo", { authInfo })
  const navigate = useNavigate();
  const [users, setUsers] = useState<DocumentData>([]);

  ///////////////////////////////
  ////////// 細かい処理 ///////////
  ///////////////////////////////
  // 画面遷移
  const move = (path: any) => {
    navigate(path);
    window.location.reload();
    handleDrawerToggle();
  };
  // drawer用処理
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  // 追加: Drawer の開閉
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Drawer の開閉状態を反転
  };

  // ベルのポップオーバー処理
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  ///////////////////////////////
  ///////// User情報取得 /////////
  ///////////////////////////////
  // useEffect(() => {
  //   const userDocumentRef = doc(db, "users", authInfo.uid || "");
  //   getDoc(userDocumentRef).then((documentSnapshot) => {
  //     if (documentSnapshot.exists()) {
  //       // const user = documentSnapshot.data();
  //       console.log("User Document data:", documentSnapshot.data());
  //       setUsers(documentSnapshot.data());
  //     } else {
  //       console.log("No such User!");
  //     }
  //   });
  // }, []);

  // type AuthLoginInfo = {
  //   isLogin: boolean;
  // };
  // /* ↓関数「handleSubmit」を定義 */
  // const HandleLogout = async (e: { preventDefault: () => void }) => {
  //   // console.log("HandleLogout呼ばれたよ");
  //   // console.log(e);
  //   e.preventDefault();
  //   try {
  //     //setcontext的なことをする
  //     setAuthInfo({
  //       email: "",
  //       isLogin: false,
  //       uid: "",
  //       name: "",
  //       userImageUrl: "https://picsum.photos/200/300",
  //       visited: false,
  //     });
  //     signOut(auth);

  //     // console.log("logoutできてるっぽい");
  //   } catch (error) {
  //     alert("ログアウトできていないよ");
  //   }
  // };

  ///////////////////////////////
  /////////// 通知処理 ///////////
  ///////////////////////////////
  //////////////////
  ///// message ////
  //////////////////
  // message取得用の定義
  type msgType = {
    timestamp: Timestamp;
    to: string;
    from: string;
    message: string;
    mid: string;
    mmid: string;
  };
  const [messages, setMessages] = useState<msgType[]>([]);
  const [memberInfo, setMemberInfo] = useState<
    {
      [x: string]: React.ReactNode;
      uid: string;
    }[]
  >([]);

  // 「Toが自分」かつ「ReadがFalse」のmessageを取得
  const UseMessageListener = async () => {
    // messageコレクションを読み込む
    const MsgCollectionRef = collection(db, "message");
    const q = query(
      MsgCollectionRef,
      where("to", "==", authInfo.uid),
      where("read", "==", false),
      orderBy("timestamp", "asc")
    );
    const querySnapshot = await getDocs(q);
    setMessages(
      querySnapshot.docs.map((doc) => ({
        // ...doc.data(),
        to: doc.data().to,
        from: doc.data().from,
        read: doc.data().read,
        message: doc.data().message,
        mid: doc.data().mid,
        timestamp: doc.data().timestamp,
        mmid: doc.id,
      }))
    );
    let message_array: any = [
      querySnapshot.docs.map((doc) => ({
        ...doc.data(),
      })),
    ];
    // console.log("message_array", message_array);
    let member_array: any = [];
    message_array[0].map((doc: any) => {
      member_array.push(doc.from);
    });
    let member_set: any = new Set(member_array); // 重複をなくす(Set)
    // console.log("member_set", member_set);
    let member = Array.from(member_set); // 配列型にする
    // console.log("member", member);
    let user_array: any = [];
    member.map((uid: any) => {
      const userDocumentRef = doc(db, "users", uid || "");
      // StateにUidを入れる
      // User情報を取得し、memberInfoに入れる
      getDoc(userDocumentRef).then((documentSnapshot) => {
        if (documentSnapshot.exists()) {
          user_array = [
            ...user_array,
            { ...documentSnapshot.data(), uid: uid },
          ];
          setMemberInfo(user_array);
        } else {
          console.log("No such User!");
        }
        return user_array;
      });
    });
  };

  ///////////////
  /// 画面遷移////
  ///////////////
  const moveWithUserInfo = async (to: To, memberInfo: any) => {
    navigate(to, {
      state: {
        userImageUrl: memberInfo.userImageUrl,
        name: memberInfo.name,
        role: memberInfo.role,
        email: memberInfo.email,
        comment: memberInfo.comment,
        uid: memberInfo.uid,
      },
    });
    handleClose();
  };
  const moveToBBS = async (to: To, bbsOption: string) => {
    navigate(to, {
      state: {
        bbsOption: bbsOption,
      },
    });
    handleClose();
  };

  // ●●からメッセージが届いていますを表示
  const popover = (
    <div>
      {memberInfo.map((memberInfo) => (
        <div key={memberInfo.uid}>
          <List>
            <ListItemButton
              onClick={() => moveWithUserInfo("/message", memberInfo)}
            >
              <ListItemIcon>
                <Avatar src='https://picsum.photos/200/300' />
              </ListItemIcon>
              <ListItemText
                secondary={memberInfo.name + "からメッセージが届いています。"}
              />
            </ListItemButton>
          </List>
        </div>
      ))}
    </div>
  );
  useEffect(() => {
    UseMessageListener();
  }, []);
  // console.log("messagesState", messages);
  // console.log("messageUser", memberInfo);

  return (
    <>
      <AppBar position='fixed' color='primary' className='mb-5'>
        <Toolbar>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={0}>
              {/* ハンバーガーメニュー */}
              <IconButton
                size='large'
                edge='start'
                color='inherit'
                aria-label='menu'
                sx={{ mr: 2 }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={0}>
              {/* ロゴ表示 */}
              <Link href={`/`}>
                <img src={pcLogo} alt='ロゴ' style={{ width: 100 }} />
              </Link>
            </Grid>
            <Grid item xs />
            <Grid item xs={3}>
              {authInfo.isLogin == true ? (
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='center'
                  spacing={2}
                >
                  {/* <Grid item xs={4}> */}
                  {/* <button onClick={HandleLogout}>ログアウト</button> */}
                  {/* メッセージリストへ飛ぶアイコン */}
                  <NavLink to={`/message`}>
                    <ChatBubbleOutlineIcon style={{ color: "white" }} />
                  </NavLink>
                  {/* </Grid>
                  <Grid item xs={4}> */}
                  {/* 通知ポップアップ表示アイコン */}
                  {messages.length != 0 ? (
                    <>
                      {/* <Box> */}
                      <IconButton
                        sx={{ p: 0 }}
                        aria-label='bell'
                        onClick={handleClick}
                      >
                        <NotificationsNoneIcon style={{ color: "#ff6b00" }} />
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {popover}
                        {/* <div>
                          {messages.map((message) => (
                            <div key={message.uid}>
                              <List>
                                <ListItemButton
                                  onClick={() => ReadMsg(message.uid)}
                                >
                                  <ListItemIcon>
                                    <Avatar src='https://picsum.photos/200/300' />
                                  </ListItemIcon>
                                  <ListItemText primary={message.name} />
                                </ListItemButton>
                              </List>
                            </div>
                          ))}
                        </div> */}
                      </Popover>
                    </>
                  ) : (
                    <NotificationsNoneIcon />
                  )}
                  {/* マイページ遷移の丸画像ボタン */}
                  <NavLink to={`/user/${authInfo.uid}`} onClick={PostDetail}>
                    {authInfo.userImageUrl != undefined ? (
                      <Avatar src={authInfo.userImageUrl} />
                    ) : (
                      <Avatar src='https://picsum.photos/200/300' />
                    )}
                  </NavLink>
                </Stack>
              ) : (
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='center'
                >
                  <Link href='/login' color='inherit' underline='none'>
                    Login
                  </Link>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='temporary'
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <List color='primaly'>
          <ListItem>
            <ListItemButton component='a' href='/'>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary='Home' />
            </ListItemButton>
          </ListItem>
          {authInfo.isLogin == true ? (
            <ListItem>
              <ListItemButton onClick={() => move(`/user/${authInfo.uid}`)}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText
                  primary='Mypage'
                  sx={[{ "&:hover": { color: "#0d6efd" } }]}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            ""
          )}
          {authInfo.isLogin == true ? (
            <ListItem>
              <ListItemButton component='a' href='/CreateProject'>
                <ListItemIcon>
                  <DynamicFeedIcon />
                </ListItemIcon>
                <ListItemText primary='CreateProject' />
              </ListItemButton>
            </ListItem>
          ) : (
            ""
          )}
          <ListItem>
            <ListItemButton component='a' href='/bbs'>
              <ListItemIcon>
                <ContentPasteIcon />
              </ListItemIcon>
              <ListItemText primary='掲示板' />
            </ListItemButton>
          </ListItem>
          {authInfo.isLogin == true ? (
            <ListItem>
              <ListItemButton component='a' href='/bbs/private'>
                <ListItemIcon>
                  <ContentPasteIcon />
                </ListItemIcon>
                <ListItemText primary={authInfo.role + "掲示板"} />
              </ListItemButton>
            </ListItem>
          ) : (
            ""
          )}
        </List>
      </Drawer>
    </>
  );
  // }
};
