import React, { useContext, useEffect } from "react";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Image } from "mui-image";
import { Ratio } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import { Navigate, To, useNavigate } from "react-router-dom";

/////////////////////////////////
///////// 画像インポート //////////
////////////////////////////////
import topImg from "components/assets/image/LP202210/ROMEO-LP-top.jpg";
import connectImg from "components/assets/image/LP202210/connect.png";
import collabImg from "components/assets/image/LP202210/Collab.png";
import Report from "components/assets/image/LP202210/Report.png";
import Researchers from "components/assets/image/LP202210/Researchers.png";
import Search from "components/assets/image/LP202210/search.png";
import Hiring from "components/assets/image/LP202210/Hiring.png";
import Project from "components/assets/image/LP202210/project.png";
import Messages from "components/assets/image/LP202210/Messages.png";

const LP: React.FC = () => {
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  useEffect(() => {
    setAuthInfo({
      email: authInfo.email,
      isLogin: authInfo.isLogin,
      uid: authInfo.uid,
      name: authInfo.name,
      userImageUrl: authInfo.userImageUrl,
      visited: true,
      role: authInfo.role,
    });
    // console.log("authInfo", authInfo);
  }, []);
  // 画面遷移処理
  const move = async (path: To) => {
    navigate(path);
  };

  // 表示時に最上部を表示する
  window.scrollTo(0, 0);

  ///////////////////////////////////////
  /////////////// return ////////////////
  ///////////////////////////////////////
  const LPButton = () => {
    return (
      <Box
        sx={{
          width: "100%",
          my: 5,
          textAlign: "center",
          display: { xs: "block", md: "inline-flex" },
          justifyContent: "center",
        }}
      >
        <Button
          variant='contained'
          sx={{
            mx: { xs: 0, md: 2 },
            my: 1,
            width: { xs: "100%", md: 250 },
            height: 50,
            borderRadius: "25px",
            backgroundColor: "secondary.dark",
            color: "primary.light",
            fontWeight: "bold",
            fontSize: 20,
          }}
          onClick={() => move("/")}
        >
          プロジェクトを見る
        </Button>
        <Button
          variant='contained'
          sx={{
            mx: { xs: 0, md: 2 },
            my: 1,
            width: { xs: "100%", md: 250 },
            height: 50,
            borderRadius: "25px",
            backgroundColor: "secondary.dark",
            color: "primary.light",
            fontWeight: "bold",
            fontSize: 20,
          }}
          onClick={() => move("/login/")}
        >
          ログイン/新規登録
        </Button>
      </Box>
    );
  };
  ///////////////////////////////////////
  /////////////// return ////////////////
  ///////////////////////////////////////
  return (
    <>
      <div
        className='App-content mt-4'
        style={{
          // backgroundImage: `image-url('image/orange.png'})`,
          background: `linear-gradient(#f35d17, #9198e5)`,
        }}
      >
        {/* <Box sx={{ mx: "auto" }} alignItems='center'> */}
        <Stack
          alignItems='center'
          sx={{
            pt: 5,
            width: "90%",
            maxWidth: "md",
            mx: "auto",
            backgroundColor: "white",
          }}
        >
          {/* 概要紹介 */}
          <Container>
            <Box sx={{ maxWidth: "md" }}>
              <Image src={topImg} duration={0} />
            </Box>
            <Box sx={{ mt: 20 }}>
              <Box
                sx={{ p: 2, borderRadius: 5 }}
                style={{ background: "#FFEEEA" }}
              >
                <Typography
                  variant='h5'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  ROMEOは日本全国の専門知識とスタートアップをつなげます
                </Typography>
              </Box>
              <Grid container>
                <Grid item md={6}>
                  <Typography
                    variant='body1'
                    color={"primary.main"}
                    // sx={{ width: "60%" }}
                    sx={{ mt: 5, m: { sm: 3 } }}
                  >
                    日本では特許出願数に比べて起業の数が少ない。
                    <br />
                    <br />
                    専門知識に裏付けされたサービスを生み出すことで、スタートアップの信用力、価値が向上します。
                    <br />
                    <br />
                    専門知識を提供する方にはスタートアップに参画するという体験を通して起業のノウハウやアイデアを事業化まで持っていくためにするべきことを学んでいただくことができます。
                  </Typography>
                </Grid>
                <Grid item xs='auto' />
                <Grid item sm={8} md={6} sx={{ ml: { sm: 15, md: 0 } }}>
                  <Image src={connectImg} duration={0} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ my: 10 }}>
              <Box
                sx={{ p: 2, borderRadius: 5 }}
                style={{ background: "#F9EAFF" }}
              >
                <Typography
                  variant='h5'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  起業家、有識者、投資家の三者が話し合える場を提供します。
                </Typography>
              </Box>
              <Grid container>
                <Grid item md={6}>
                  <Typography
                    variant='body1'
                    color={"primary.main"}
                    // sx={{ width: "60%" }}
                    sx={{ mt: 5, m: { sm: 3 } }}
                  >
                    今までこの三者が別々に話すことはあっても、共にサービスについて話すことのできる場はなかったと思います。
                    <br />
                    <br />
                    ROMEOではこの三者が同じ話題について話し合える場を提供することで、さまざまな視点からの意見を拾い上げることができ、相互作用を産むことができます。
                  </Typography>
                </Grid>
                <Grid item xs='auto' />
                <Grid item sm={8} md={6} sx={{ ml: { sm: 15, md: 0 } }}>
                  <Image src={collabImg} duration={0} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mb: 15 }}>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  display: { xs: "block", md: "inline-flex" },
                  justifyContent: "center",
                }}
              >
                <AnchorLink href='#LP1' offset='80' style={{ color: "#fff" }}>
                  <Button
                    variant='contained'
                    sx={{
                      mr: 2,
                      my: 1,
                      width: { xs: "100%", md: 150 },
                      height: 50,
                      borderRadius: "25px",
                      backgroundColor: "secondary.light",
                      color: "primary.light",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    起業家の方
                  </Button>
                </AnchorLink>
                <AnchorLink href='#LP2' offset='80' style={{ color: "#fff" }}>
                  <Button
                    variant='contained'
                    sx={{
                      mr: 2,
                      my: 1,
                      width: { xs: "100%", md: 150 },
                      height: 50,
                      borderRadius: "25px",
                      backgroundColor: "secondary.light",
                      color: "primary.light",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    有識者の方
                  </Button>
                </AnchorLink>
                <AnchorLink href='#LP3' offset='80' style={{ color: "#fff" }}>
                  <Button
                    variant='contained'
                    sx={{
                      my: 1,
                      width: { xs: "100%", md: 150 },
                      height: 50,
                      borderRadius: "25px",
                      backgroundColor: "secondary.light",
                      color: "primary.light",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                  >
                    投資家の方
                  </Button>
                </AnchorLink>
              </Box>
              <LPButton />
            </Box>
          </Container>
          {/* 起業家Section */}
          <Container id='LP1' sx={{ mb: 15 }}>
            <Box
              sx={{ p: 2, borderRadius: 5 }}
              style={{ background: "#F9EAFF" }}
            >
              <Typography
                variant='h5'
                color={"primary.main"}
                sx={{ fontWeight: "bold" }}
              >
                起業家の方へ
              </Typography>
            </Box>
            <Typography
              variant='body1'
              color={"primary.main"}
              sx={{ mt: 5, m: { sm: 3 } }}
            >
              ROMEOは起業家に対して総合的な支援をおこなうサービスです。
              <br />
              ご相談いただければ特定の専門分野の有識者へとお繋ぎいたします。
            </Typography>
            <Grid
              container
              justifyContent='center'
              columnSpacing={8}
              sx={{ mt: 5, mb: 10 }}
            >
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" }, mb: 5 }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Researchers} duration={0} fit='contain' />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  有識者募集
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    専門知識に裏付けされたサービスを生み出すことで、スタートアップの信用力、価値が向上します
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" }, mb: 5 }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Report} duration={0} fit='contain' />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  活動報告
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    実際に行っている活動や現在の課題などを投稿することで、広告や意見募集掲示板として使用できます。
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" } }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Search} duration={0} fit='contain' />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  ユーザー調査
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    「ユーザーの需要」や「ユーザー目線の課題」があるか調査することでPMFを支援します。運営にご相談ください。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <LPButton />
          </Container>
          {/* 有識者Section */}
          <Container id='LP2' sx={{ mb: 15 }}>
            <Box
              sx={{ p: 2, borderRadius: 5 }}
              style={{ background: "#F9EAFF" }}
            >
              <Typography
                variant='h5'
                color={"primary.main"}
                sx={{ fontWeight: "bold" }}
              >
                有識者の方へ
              </Typography>
            </Box>
            <Typography
              variant='body1'
              color={"primary.main"}
              sx={{ mt: 5, m: { sm: 3 } }}
            >
              ROMEOでは興味のあるプロジェクトの募集職種を確認し、参画することができます。
              <br />
              報酬の支払いに関しては現在は現金のみとなりますが、将来的に他の形式(暗号資産、ポイントなど)の形式にも対応していく予定です。
              <br />
              アップデートをお待ちください。
            </Typography>
            <Grid
              container
              justifyContent='center'
              columnSpacing={8}
              sx={{ mt: 5, mb: 10 }}
            >
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" }, mb: 5 }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Researchers} duration={0} fit='contain' />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  プロジェクト参画
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    ワクワクするプロジェクトに参画してスタートアップのベンチャー精神を生で感じることができます。
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" }, mb: 5 }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Report} duration={0} fit='contain' />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  自分の意見を投稿
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    プロジェクトの課題や活動についてコメントを残すことができます。
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" } }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Search} duration={0} fit='contain' />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ fontWeight: "bold" }}
                >
                  事業化のノウハウを学ぶ
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    参画することでスタートアップの動きを生で感じ、アイデアを事業化していく過程を体験できます。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <LPButton />
          </Container>
          {/* 投資家Section */}
          <Container id='LP3' sx={{ mb: 30 }}>
            <Box
              sx={{ p: 2, borderRadius: 5 }}
              style={{ background: "#F9EAFF" }}
            >
              <Typography
                variant='h5'
                color={"primary.main"}
                sx={{ fontWeight: "bold" }}
              >
                投資家の方へ
              </Typography>
            </Box>
            <Typography
              variant='body1'
              color={"primary.main"}
              sx={{ mt: 5, m: { sm: 3 } }}
            >
              ROMEOではプロジェクトに参画した有識者やプロジェクトの詳細、活動報告などプロジェクトの情報を一貫して確認することができます。
              <br />
              「業種」や「投資受付可否」によってフィルタリングし、ご興味のあるプロジェクトにメッセージ機能を使ってコンタクトをとっていただくことができます。
            </Typography>
            <Grid
              container
              justifyContent='center'
              columnSpacing={8}
              sx={{ mt: 5, mb: 10 }}
            >
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" }, mb: 5 }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Hiring} duration={0} fit='contain' />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ mt: 5, fontWeight: "bold" }}
                >
                  有識者情報
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    その有識者がどのような考えで、今までどのような仕事をしてきたのか確認することができます。
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" }, mb: 5 }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Project} duration={0} />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ mt: 5, fontWeight: "bold" }}
                >
                  プロジェクト詳細・活動報告
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    多数のスタートアップの実際の活動をまとめて確認することができます。
                    <br />
                    参画している人間の人柄を掲示板での会話で感じることができます。
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                textAlign='center'
                xs={12}
                sm={6}
                md={4}
                sx={{ width: { xs: "80%", md: "100%" } }}
              >
                <Box
                  sx={{
                    width: { xs: "65%", sm: "100%" },
                    mx: "auto",
                  }}
                >
                  <Image src={Messages} duration={0} />
                </Box>
                <Typography
                  variant='body1'
                  color={"primary.main"}
                  sx={{ mt: 5, fontWeight: "bold" }}
                >
                  メッセージ
                </Typography>
                <Box sx={{ lineHeight: 0, mt: 2 }}>
                  <Typography variant='caption' color={"primary.main"}>
                    起業家や有識者に直接メッセージを送ることができます。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <LPButton />
          </Container>
          {/* フッター */}
          <Container sx={{ background: "#df6d57a1" }}>
            <Box
              sx={{
                my: 10,
                textAlign: "center",
              }}
            >
              <Typography variant='body2' color={"inherit"}>
                Copyright Bizmarq 2022
              </Typography>
            </Box>
          </Container>
        </Stack>
        {/* </Box> */}
      </div>
    </>
  );
};
export default LP;
