import React, { ReactNode, useContext, useEffect, useState } from "react";
import logo from "logo.svg";
import { auth, db } from "components/firebase-config";
import { AuthInfoContext } from "components/context/AuthContextProvider";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  orderBy,
  DocumentData,
  QuerySnapshot,
  limit,
} from "firebase/firestore";
import { NavLink, To, useNavigate } from "react-router-dom";
import pcLogo from "components/assets/image/ROMEO-header-logo.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Tabs,
  Typography,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CompressOutlined,
  DisplaySettingsRounded,
  FireExtinguisher,
} from "@mui/icons-material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { resolve } from "path";
import { LoadingButton } from "@mui/lab";

const Top: React.FC = () => {
  const [authInfo, setAuthInfo] = useContext(AuthInfoContext);
  const user: any = auth.currentUser;
  const navigate = useNavigate();
  // console.log("Top_authInfo", { authInfo });
  // userドキュメントに同じuidのドキュメントが存在しなければ
  // console.log("Top-document取得用uid", authInfo.email);
  useEffect(() => {
    if (authInfo.uid != "") {
      const userDocumentRef = doc(db, "users", authInfo.uid);
      getDoc(userDocumentRef).then(async (documentSnapshot) => {
        // console.log("loginemail - docref", documentSnapshot.data());
        if (documentSnapshot.exists()) {
          // console.log("loginemail - docdata", documentSnapshot.data());
          // console.log("loginemail - 判定できてるかな");
          setAuthInfo({
            email: authInfo.email,
            isLogin: authInfo.isLogin,
            uid: authInfo.uid,
            name: documentSnapshot.get("name"),
            userImageUrl: "https://picsum.photos/200/300",
            visited: false,
            role: authInfo.role,
          });
          console.log("authInfoをセット", authInfo);
        } else {
          console.log("No such document! I will make new user docment");
          // 存在しなければfirestoreにuidをキーとして作成
          await setDoc(doc(db, "users", authInfo.uid), {
            name: "プロフィールを編集してください",
            role: "",
            email: authInfo.email,
          });
        }
      });
    }
    // query表示を実行
    // console.log("readPostを実行");
    readExpertPost();
    readRecruitPost();
    readRecentPost();
  }, []);

  ///////////////////////////////////////
  ////// queryで有識者依頼Postを取得 ///////
  ///////////////////////////////////////
  const [expertPosts, setExpertPosts] = useState<
    {
      [x: string]: ReactNode;
      pid: string;
    }[]
  >([]);

  const readExpertPost = async () => {
    // console.log("readPostにはいった");
    const PostsCollectionRef = collection(db, "posts");
    const q = query(
      PostsCollectionRef,
      where("category", "==", "expertPost"),
      orderBy("updateDate", "desc"),
      limit(3)
    );

    const querySnapshot = await getDocs(q);
    // post_arrayに入れてからsetStateするパターン
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                pid: posts_doc.id,
              },
            ];
            setExpertPosts(post_array);
          }
        );
      })
    );
  };

  ///////////////////////////////////////
  ////// queryで顧問募集Postを取得 ///////
  ///////////////////////////////////////

  const [recruitPosts, setRecuruitPosts] = useState<
    {
      [x: string]: ReactNode;
      pid: string;
    }[]
  >([]);

  const readRecruitPost = async () => {
    // console.log("readPostにはいった");
    const PostsCollectionRef = collection(db, "posts");
    const q = query(
      PostsCollectionRef,
      where("category", "==", "recruitPost"),
      orderBy("updateDate", "desc"),
      limit(3)
    );
    const querySnapshot = await getDocs(q);
    // post_arrayに入れてからsetStateするパターン
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                pid: posts_doc.id,
              },
            ];
            setRecuruitPosts(post_array);
          }
        );
      })
    );
  };

  ///////////////////////////////////////
  ////// queryで近況報告Postを取得 ///////
  ///////////////////////////////////////

  const [recentPosts, setRecentPosts] = useState<
    {
      [x: string]: ReactNode;
      pid: string;
    }[]
  >([]);

  const readRecentPost = async () => {
    // console.log("readRecentPostにはいった");
    const PostsCollectionRef = collection(db, "posts");
    const q = query(
      PostsCollectionRef,
      where("category", "==", "recentPost"),
      orderBy("updateDate", "desc"),
      limit(5)
    );
    const querySnapshot = await getDocs(q);
    // post_arrayに入れてからsetStateするパターン
    let post_array: any = [];
    await Promise.all(
      querySnapshot.docs.map((posts_doc) => {
        getDoc(doc(db, "users", posts_doc.get("uid"))).then(
          (documentSnapshot) => {
            post_array = [
              ...post_array,
              {
                ...posts_doc.data(),
                ...documentSnapshot.data(),
                pid: posts_doc.id,
              },
            ];
            setRecentPosts(post_array);
          }
        );
      })
    );
  };

  // 移動
  const moveWithInfo = async (path: To, category: string) => {
    // navigate("/mypage/edit", {
    navigate(path, {
      state: {
        category: category,
      },
    });
  };

  // タブの設定
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (authInfo.isLogin == true) {
    return (
      <div className='App-content mt-2 pt-5'>
        {/* <Typography variant="h1" component="h2" mt={20}>
          ユーザ詳細
        </Typography> */}
        <img
          src={pcLogo}
          alt='ロゴ'
          style={{ width: 200 }}
          className='mt-5 ms-3'
        />
        <Typography
          variant='h5'
          component='h2'
          mt={2}
          ml={4}
          sx={{ fontWeight: "bold" }}
        >
          ROMEOのキャッチコピー
        </Typography>
        <Typography
          variant='subtitle2'
          component='h2'
          mt={2}
          ml={7}
          sx={{ fontWeight: "bold" }}
        >
          ROMEOの対象者とか軽い説明というか次のアクションを書く
        </Typography>

        {/* 最近の依頼 */}
        <Box
          sx={{
            width: 170,
            height: 50,
            backgroundColor: "primary.light",
            color: "primary.main",
            fontWeight: "bold",
            borderRadius: "25px",
            textAlign: "center",
            lineHeight: 2.5,
          }}
          mt={3}
        >
          最近の依頼
        </Box>

        <Box sx={{ width: "100%", typography: "body1", mt: 5 }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList
                onChange={handleChange}
                variant='fullWidth'
                aria-label='lab API tabs example'
                textColor='inherit'
              >
                <Tab
                  label='有識者投稿'
                  value='1'
                  sx={{
                    bgcolor: "secondary.main",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                />
                <Tab
                  label='顧問募集'
                  value='2'
                  sx={{
                    bgcolor: "secondary.dark",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                />
                {/* </Tabs> */}
              </TabList>
            </Box>
            <TabPanel value='1'>
              <Typography variant='h5' mb={3} sx={{ fontWeight: "bold" }}>
                有識者投稿
              </Typography>
              {expertPosts.map((expertPost) => (
                <Card
                  sx={{ minWidth: 275, backgroundColor: "primary.main", my: 2 }}
                >
                  <CardContent>
                    {/* <p>{`console.log("ううううううううう",expertPost);`}</p> */}
                    <div key={expertPost.pid}>
                      <NavLink to={`/posts/${expertPost.pid}`}>
                        <Typography
                          variant='h5'
                          component='div'
                          color='primary.light'
                        >
                          {expertPost.title}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='primary.light'>
                          {expertPost.content}
                        </Typography>
                        <Typography variant='body2' color='primary.light'>
                          {expertPost.name}
                        </Typography>
                        <Typography variant='body2' color='primary.light'>
                          {expertPost.pid}
                        </Typography>
                      </NavLink>
                    </div>
                  </CardContent>
                </Card>
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant='contained'
                  sx={{
                    mr: 4,
                    width: 150,
                    height: 50,
                    borderRadius: "25px",
                    backgroundColor: "secondary.light",
                    color: "primary.light",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                  onClick={() => moveWithInfo("/posts", "expertPost")}
                >
                  More
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value='2'>
              <Typography variant='h5' mb={3} sx={{ fontWeight: "bold" }}>
                顧問募集
              </Typography>
              {recruitPosts.map((recruitPost) => (
                <Card
                  sx={{ minWidth: 275, backgroundColor: "primary.main", my: 2 }}
                >
                  <CardContent>
                    <div key={recruitPost.pid}>
                      <NavLink to={`/posts/${recruitPost.pid}`}>
                        <Typography
                          variant='h5'
                          component='div'
                          color='primary.light'
                        >
                          {recruitPost.title}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='primary.light'>
                          {recruitPost.content}
                        </Typography>
                        <Typography variant='body2' color='primary.light'>
                          {recruitPost.name}
                        </Typography>
                      </NavLink>
                    </div>
                  </CardContent>
                </Card>
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant='contained'
                  sx={{
                    mr: 4,
                    width: 150,
                    height: 50,
                    borderRadius: "25px",
                    backgroundColor: "secondary.light",
                    color: "primary.light",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                  onClick={() => moveWithInfo("/posts", "recruitPost")}
                >
                  More
                </Button>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>

        {/* 活動報告 */}
        <Box
          sx={{
            width: 170,
            height: 50,
            backgroundColor: "primary.light",
            color: "primary.main",
            fontWeight: "bold",
            borderRadius: "25px",
            textAlign: "center",
            lineHeight: 2.5,
          }}
          mt={3}
        >
          活動報告
        </Box>
        {recentPosts.map((recentPost) => (
          <Card sx={{ minWidth: 275, backgroundColor: "primary.main", my: 2 }}>
            <CardContent>
              <div key={recentPost.pid}>
                <NavLink to={`/posts/${recentPost.pid}`}>
                  <Typography
                    variant='h5'
                    component='div'
                    color='primary.light'
                  >
                    {recentPost.title}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color='primary.light'>
                    {recentPost.content}
                  </Typography>
                  <Typography variant='body2' color='primary.light'>
                    {recentPost.name}
                  </Typography>
                  <Typography variant='body2' color='primary.light'>
                    {recentPost.pid}
                  </Typography>
                </NavLink>
              </div>
            </CardContent>
          </Card>
        ))}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 5 }}>
          <Button
            variant='contained'
            sx={{
              mr: 4,
              width: 150,
              height: 50,
              borderRadius: "25px",
              backgroundColor: "secondary.light",
              color: "primary.light",
              fontWeight: "bold",
              fontSize: 20,
            }}
            onClick={() => moveWithInfo("/posts", "recentPost")}
          >
            More
          </Button>
        </Box>
      </div>
    );
  } else {
    return (
      <div className='App-content mt-2 pt-5'>
        {/* <Typography variant="h1" component="h2" mt={20}>
          ユーザ詳細
        </Typography> */}
        <img
          src={pcLogo}
          alt='ロゴ'
          style={{ width: 200 }}
          className='mt-5 ms-3'
        />
        <Typography
          variant='h5'
          component='h2'
          mt={2}
          ml={4}
          sx={{ fontWeight: "bold" }}
        >
          ROMEOのキャッチコピー
        </Typography>
        <Typography
          variant='subtitle2'
          component='h2'
          mt={2}
          ml={7}
          sx={{ fontWeight: "bold" }}
        >
          ROMEOの対象者とか軽い説明というか次のアクションを書く
        </Typography>

        {/* 最近の依頼 */}
        <Box
          sx={{
            width: 170,
            height: 50,
            backgroundColor: "primary.light",
            color: "primary.main",
            fontWeight: "bold",
            borderRadius: "25px",
            textAlign: "center",
            lineHeight: 2.5,
          }}
          mt={3}
        >
          最近の依頼
        </Box>

        <Box sx={{ width: "100%", typography: "body1", mt: 5 }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList
                onChange={handleChange}
                variant='fullWidth'
                aria-label='lab API tabs example'
                textColor='inherit'
              >
                <Tab
                  label='有識者投稿'
                  value='1'
                  sx={{
                    bgcolor: "secondary.main",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                />
                <Tab
                  label='顧問募集'
                  value='2'
                  sx={{
                    bgcolor: "secondary.dark",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                />
                {/* </Tabs> */}
              </TabList>
            </Box>
            <TabPanel value='1'>
              <Typography variant='h5' mb={3} sx={{ fontWeight: "bold" }}>
                有識者投稿
              </Typography>
              {expertPosts.map((expertPost) => (
                <Card
                  sx={{ minWidth: 275, backgroundColor: "primary.main", my: 2 }}
                >
                  <CardContent>
                    {/* <p>{`console.log("ううううううううう",expertPost);`}</p> */}
                    <div key={expertPost.pid}>
                      <NavLink to={`/posts/${expertPost.pid}`}>
                        <Typography
                          variant='h5'
                          component='div'
                          color='primary.light'
                        >
                          {expertPost.title}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='primary.light'>
                          {expertPost.content}
                        </Typography>
                        <Typography variant='body2' color='primary.light'>
                          {expertPost.name}
                        </Typography>
                        <Typography variant='body2' color='primary.light'>
                          {expertPost.pid}
                        </Typography>
                      </NavLink>
                    </div>
                  </CardContent>
                </Card>
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant='contained'
                  sx={{
                    mr: 4,
                    width: 150,
                    height: 50,
                    borderRadius: "25px",
                    backgroundColor: "secondary.light",
                    color: "primary.light",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                  onClick={() => moveWithInfo("/posts", "expertPost")}
                >
                  More
                </Button>
              </Box>
            </TabPanel>
            <TabPanel value='2'>
              <Typography variant='h5' mb={3} sx={{ fontWeight: "bold" }}>
                顧問募集
              </Typography>
              {recruitPosts.map((recruitPost) => (
                <Card
                  sx={{ minWidth: 275, backgroundColor: "primary.main", my: 2 }}
                >
                  <CardContent>
                    <div key={recruitPost.pid}>
                      <NavLink to={`/posts/${recruitPost.pid}`}>
                        <Typography
                          variant='h5'
                          component='div'
                          color='primary.light'
                        >
                          {recruitPost.title}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color='primary.light'>
                          {recruitPost.content}
                        </Typography>
                        <Typography variant='body2' color='primary.light'>
                          {recruitPost.name}
                        </Typography>
                      </NavLink>
                    </div>
                  </CardContent>
                </Card>
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant='contained'
                  sx={{
                    mr: 4,
                    width: 150,
                    height: 50,
                    borderRadius: "25px",
                    backgroundColor: "secondary.light",
                    color: "primary.light",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                  onClick={() => moveWithInfo("/posts", "recruitPost")}
                >
                  More
                </Button>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>

        {/* 活動報告 */}
        <Box
          sx={{
            width: 170,
            height: 50,
            backgroundColor: "primary.light",
            color: "primary.main",
            fontWeight: "bold",
            borderRadius: "25px",
            textAlign: "center",
            lineHeight: 2.5,
          }}
          mt={3}
        >
          活動報告
        </Box>
        {recentPosts.map((recentPost) => (
          <Card sx={{ minWidth: 275, backgroundColor: "primary.main", my: 2 }}>
            <CardContent>
              <div key={recentPost.pid}>
                <NavLink to={`/posts/${recentPost.pid}`}>
                  <Typography
                    variant='h5'
                    component='div'
                    color='primary.light'
                  >
                    {recentPost.title}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color='primary.light'>
                    {recentPost.content}
                  </Typography>
                  <Typography variant='body2' color='primary.light'>
                    {recentPost.name}
                  </Typography>
                  <Typography variant='body2' color='primary.light'>
                    {recentPost.pid}
                  </Typography>
                </NavLink>
              </div>
            </CardContent>
          </Card>
        ))}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 5 }}>
          <Button
            variant='contained'
            sx={{
              mr: 4,
              width: 150,
              height: 50,
              borderRadius: "25px",
              backgroundColor: "secondary.light",
              color: "primary.light",
              fontWeight: "bold",
              fontSize: 20,
            }}
            onClick={() => moveWithInfo("/posts", "recentPost")}
          >
            More
          </Button>
        </Box>
      </div>
    );
  }
};

export default Top;
