import algoliasearch from "algoliasearch";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID as string,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY as string
);
export const serchProject = async (filter: string) => {
  const index = client.initIndex("projectIdx");
  console.log("serchProjectのFilter", filter);
  return await index.search("", {
    filters: filter
    // filters: "category:project AND (investment:true OR investment:false)"
  });
};
